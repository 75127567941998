<template>
  <div class="sign">
    <div class="sign__left">
      <img src="img/login.png" class="side-home-image" />
    </div>
    <div class="sign__right">
      <div class="sign__box">
        <img src="img/my-farewell-logo-new.png" width="100%" class="mb-4" />
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from "vuex";

export default {
  props: {
    action: String,
  },
  data() {
    return {};
  },
  computed: {},
};
</script>

<style lang="scss">
.side-home-image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: right bottom;
  object-position: right bottom;
}
// .sign__left {
//   background: url("/img/login.png") center;
//   background-size: cover;
//   height: 100%;
//   overflow: hidden;
// }
.sign__right {
  overflow: auto;
}

.sign__right {
  padding: --space(5);
}
// @include laptop {
.sign {
  display: grid;
  grid-template-columns: 40% 60%;
  height: 100vh;
  overflow: hidden;
}
.sign__left {
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  // padding: --space(5);
  h2 {
    font-size: 48px;
    line-height: 0.8;
  }
  h1 {
    line-height: 0.8;
    font-size: 98px;
  }
  ul {
    list-style: none;
    font-size: 24px;
  }
}
.sign__right {
  display: flex;
  // justify-content: center;
  // align-items: center;
  flex-direction: column;
}
.sign__box {
  width: 320px;
  margin: auto;
}
// }

@media (max-width: 1024px) {
  .sign {
    &__box {
      .item-form {
        width: 100% !important;
      }
    }
  }
}
@media (max-width: 780px) {
  .sign {
    display: block;
    height: auto;
    &__left {
      position: relative;
      padding-bottom: 56.25%;
      img {
        position: absolute;
        top: 0px;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    &__box {
      text-align: center;
    }
    .item-form {
      text-align: left;
    }
  }
}
@media (max-width: 450px) {
  .sign {
    &__right {
      padding: 30px;
    }
    &__box {
      width: 100%;
      text-align: left;
    }
  }
}
</style>
