<template>
  <div
    v-if="loading"
    class="p-5 flex flex--fit flex--center flex--middle h-100"
    v-shilp-loader="true"
  >
    <img src="img/my-farewell-logo-new.png" height="200" width="300" class="mt-7" />
  </div>
  <s-layout
    :class="
      isMenu
        ? 'wh--layout is-open--menu main--layout'
        : 'wh--layout main--layout'
    "
    top-size="50px"
    :left-size="collapsed ? '70px' : '240px'"
    ref="mainContainer"
    :left-classlist="['br-lightest']"
    :top-classlist="['bb-lightest']"
    full-height
    v-else
    push-top
  >
    <template #top>
      <div class="flex flex--middle">
        <s-button-group
          shape="square"
          align="left"
          class="flex__fit toggle-menu"
          size="lg"
        >
          <s-button icon="vmdi-menu" @click.native="isToggle()" />
        </s-button-group>
        <s-breadcrumbs class="flex__fluid" />
        <div class="flex__fit pr-3">
          <portal-target name="header" slim />
        </div>
      </div>
    </template>
    <template #left>
      <web-nav @isMenu="isMenu = $event" :isMenu="isMenu" />
    </template>
    <template #default>
      <router-view />
      <profile-modal />
      <payment-modal :business-data="businessData" />
    </template>
  </s-layout>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { setToken } from "@/plugins/axios";
import me from "@/api/user";
import company from "@/api/company";

export default {
  components: {
    WebNav: require("@/components/WebNav").default,
    ProfileModal: require("@/views/company/ProfileModal").default,
    PaymentModal: require("@/views/payment/Index").default,
  },
  data() {
    return {
      loading: true,
      businessData: null,
      isMenu: false,
    };
  },
  async mounted() {
    this.checkToken();
    this.startHydration(false);

    this.$root.$on("rehydrate", () => {
      this.checkToken();
      this.startHydration(true);
    });
  },
  computed: {
    ...mapState({
      collapsed: (state) => state.account.isAdminNavCollapsed,
    }),
  },
  methods: {
    ...mapActions(["hydrate"]),
    isToggle() {
      if (this.isMenu) {
        this.isMenu = false;
      } else {
        this.isMenu = true;
      }
    },
    checkToken() {
      const token = localStorage.getItem("vfa-token");
      if (!token) {
        this.$router.push({ name: "login" });
        return;
      }
      setToken(token);
    },
    async startHydration(background) {
      if (background) {
        this.loading = true;
      }

      try {
        const user = await me.get().then((res) => {
          return res;
        });

        const business = await company
          .get(user?.businesses?.[0]?.id)
          .then((res) => {
            return res;
          });

        await this.hydrate({ user, business });
        this.businessData = business;
        // console.log("business ", business, " user ", user);
        setTimeout(() => {
          if (
            user?.subscription_status === "Inactive" &&
            user.user_roles[0] !== "admin"
          ) {
            this.$shilp.modal.open("stripe-payment-modal");
          } else {
            if (!business.email) {
              this.$shilp.modal.open("company-profile-modal");
            }
          }
        }, 1000);

        this.loading = false;
      } catch (err) {
        console.error(err);
        this.loading = false;
        this.$notify({
          type: "danger",
          title: "Failed to Login!",
          message:
            "Can not fetch user data. Please login again. If issue persist, kindly contact us.",
          duration: 10000,
        });
        this.$router.replace({ name: "logout" });
      }
    },
  },
};
</script>
<style lang="scss">
.toggle-menu {
  display: none;
}
@media (max-width: 1200px) {
  body {
    .v-list {
      padding-bottom: 30px !important;
      &__content {
        overflow: hidden;
      }
      .sp-table {
        overflow: auto;
        height: 100%;
        padding-bottom: 50px !important;
      }
    }
  }
  .toggle-menu {
    display: block;
    margin-left: 3px;
  }
  .collapse-button {
    display: none !important;
  }
  .main--layout {
    display: flex;
    flex-direction: column;
    position: relative;
    > .layout__top {
      background-color: #fff;
      z-index: 10;
      .flex {
        flex-wrap: nowrap;
      }
      .breadcrumbs {
        overflow: scroll;
        flex-wrap: nowrap;
        display: flex;
        white-space: nowrap;
      }
    }
    > .layout__content {
      flex: 1;
      overflow: auto;
    }
    > .layout__left {
      position: fixed;
      top: 50px;
      left: 0px;
      bottom: 0px;
      z-index: 10;
      width: 320px;
      transition: all 0.3s;
      transform: translateX(-320px);
    }
    &.is-open--menu {
      .layout__left {
        transform: translateX(0);
      }
    }
  }
}
</style>
