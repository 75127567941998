<template>
  <item-form
    ref="item"
    :get="get"
    class="p-4 box"
    :save="save"
    :item="true"
    width="400px"
    :notify="true"
    :footer="true"
    :fields="fields"
    :redirect="false"
    :breadcrumb="false"
  >
    <template #default="{ form }">
      <div class="text-primary text-bold text-md fields-plan-full">
        Your Viewing/Visitation/Wake Wishes
      </div>
      <s-field-validate
        label="Do you wish to have a wake/visitation?"
        name="wake visitation"
        vid="wake visitation"
        rules="required"
      >
        <s-radio
          :options="coffinChoiceOptions"
          v-model="form.coffin_choice_wake_viewing"
          class="mt-1"
        />
      </s-field-validate>

      <s-button
      theme="outline"
      color="primary"
      class="button-border"
      icon="vmdi-plus-circle"
      @click.native="isViewing = !isViewing"
      v-if="(form.coffin_choice_wake_viewing === 'yes')"
      label="Add Personal wishes"
      />
      
      <div class="view-visit-form" v-if="isViewing || form.wake_viewing_location">
        <!-- <h4 class="text-primary">Funeral Transport</h4> -->
        <s-field-validate
          v-if="(form.coffin_choice_wake_viewing === 'yes')"
          label="Your preferred viewing/ wake location: (e.g. family home/ funeral home/ alternative venue)"
          rules="max:64"
        >
        <s-textbox
          type="text"
          spellcheck="true"
          v-model="form.wake_viewing_location"
        />
        </s-field-validate>

        <!-- <s-field-validate
          class="mt-3"
          v-if="(form.coffin_choice_wake_viewing === 'yes')"
          label="Would you like a public or private viewing/wake?"
          rules="max:128"
        >
        <s-textbox
          type="text"
          spellcheck="true"
          v-model="form.wake_viewing_type"
        />
        </s-field-validate> -->

        <s-field-validate
        class="mt-3"
        v-if="(form.coffin_choice_wake_viewing === 'yes')"
        label="Would you like a public or private viewing/wake?">
        <s-radio
          class="mt-1"
          :options="wakeViewingType"
          v-model="form.wake_viewing_type"
        />
        </s-field-validate>

        <s-field-validate
          class="mt-3"
          v-if="(form.coffin_choice_wake_viewing === 'yes')"
          label="Would you like the coffin to be open or closed?"
          rules="max:64"
        >
        <s-textbox
          type="text"
          spellcheck="true"
          v-model="form.coffin_open_people_see"
        />
        </s-field-validate>

        <s-field
        class="mt-3"
        v-if="(form.coffin_choice_wake_viewing === 'yes')"
        label="Would you like to choose your own coffin?">
        <s-radio
          class="mt-1"
          :options="chooseCoffin"
          v-model="form.coffin_type"
        />
        </s-field>

         <s-field-validate
          class="mt-3 ml-3"
          v-if="(form.coffin_type === 'no' && form.coffin_choice_wake_viewing === 'yes')"
          label="Do you have any instructions for your family when choosing one? (e.g. price range (low, medium or high), style of coffin)"
          rules="max:512"
        >
        <s-textbox
          type="text"
          spellcheck="true"
          v-model="form.coffin_detail"
        />
        </s-field-validate> 

        <s-field-validate
          class="mt-3"
          v-if="(form.coffin_choice_wake_viewing === 'yes')"
          label="Would you like provide any details for your presentation? (e.g. clothing, hair jewellery etc.)"
          rules="max:512"
        >
        <s-textbox
          type="text"
          spellcheck="true"
          v-model="form.wake_viewing_presentation_detail"
        />
        </s-field-validate>

        <s-field-validate
          class="mt-3"
          v-if="(form.coffin_choice_wake_viewing === 'yes')"
          label="Please detail any other viewing/ wake wishes that you may have"
          rules="max:512"
        >
        <s-textbox
          type="text"
          spellcheck="true"
          v-model="form.wake_viewing_other_detail"
        />
        </s-field-validate>

      </div>

      
    </template>
    <template #footer="{ saveItem, saving }" :class="['']">
      <s-row class="g-2">
        <s-column :size="4">
          <s-button
            fluid
            label="Back"
            theme="muted"
            @click.native="back"
            class="bg-grey-lighter text-grey"
          />
        </s-column>
        <s-column :size="4">
          <s-button
            fluid
            align="right"
            color="primary"
            :loader="saving"
            label="Next"
            icon="vmdi-arrow-right"
            @click.native="saveItem"
          />
        </s-column>
      </s-row>
    </template>
  </item-form>
</template>
<script>
import { addVisitationDetails } from "@/api/pre-planning/create";
export default {
  props: {
    prePlanningId: [Number, String],
    perPlanningDetails: Object,
  },
  data() {
    return {
      isViewing:false,
      fields: [
        "coffin_type",
        "coffin_choice_wake_viewing",
        "funeral_transport",
        "funeral_transport_wishes",
        "funeral_travel_route",
        "wake_viewing_location",
        "wake_viewing_type",
        "coffin_open_people_see",
        "coffin_detail",
        "wake_viewing_presentation_detail",
        "wake_viewing_other_detail",
      ],
      coffinChoiceOptions: [
        {
          label: "Yes",
          value: "yes",
        },
        {
          label: "No",
          value: "no",
        },
        {
          label: "Not Sure",
          value: "not_sure",
        },
        {
          label: "Let my family decide",
          value: "family_or_funeral_arranger",
        },
      ],
      wakeViewingType:[
        {
          label: "Public",
          value: "public",
        },
        {
          label: "Private",
          value: "private",
        }
      ],
      // transportOptions: [
      //   {
      //     label: "Hearse only",
      //     value: "hearse",
      //   },
      //   {
      //     label: "One Limousine (6 person transport)",
      //     value: "one_limousine",
      //   },
      //   {
      //     label: "Two Limousines (12 person transport)",
      //     value: "two_limousine",
      //   },
      // ],
      chooseCoffin:[
        {
          label:"Yes",
          value:"yes",
        },
        {
          label:"No",
          value:"no",
        }
      ],
    };
  },
  components: {
    ItemForm: require("@/components/ItemForm").default,
    //PersonalWishes: require("@/components/PersonalWishes").default,
  },
  methods: {
    get() {
      return { ...this.perPlanningDetails };
    },
    save(id, data) {
      data["prePlanningId"] = this.prePlanningId;
      return addVisitationDetails(data).then((res) => {
        this.$emit("refresh");
        this.$router.push({ name: "my-personal-wishes" });
      });
    },
    back() {
      this.$router.push({
        name: "my-ceremony-wishes",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.button-border {
  border: 1px dashed var(--color--primary) !important;
}
</style>
