export default {
	Email: require("@icon/Email").default,
	Lock: require("@icon/Lock").default,
	Delete: require("@icon/Delete").default,
	ArchiveArrowDown: require("@icon/ArchiveArrowDown").default,
	ArchiveArrowUp: require("@icon/ArchiveArrowUp").default,
	Close: require("@icon/Close").default,

	"vmdi-playlist-edit": require("@icon/PlaylistEdit").default,
	"vmdi-plus": require("@icon/Plus").default,
	"vmdi-menu": require("@icon/Menu").default,
	"vmdi-cogs": require("@icon/Cogs").default,
	"vmdi-link-icon": require("@icon/Link").default,
	"vmdi-view-dashboard": require("@icon/ViewDashboard").default,
	"vmdi-login-variant": require("@icon/LoginVariant").default,
	"vmdi-book": require("@icon/Book").default,
	"vmdi-flower": require("@icon/Flower").default,
	"vmdi-file-document": require("@icon/FileDocument").default,
	"vmdi-account-group": require("@icon/AccountGroup").default,
	"vmdi-coffin": require("@icon/Coffin").default,
	"vmdi-phone": require("@icon/Phone").default,
	"vmdi-domain": require("@icon/Domain").default,
	"vmdi-web": require("@icon/Web").default,
	"vmdi-content-copy": require("@icon/ContentCopy").default,
	"vmdi-dots-vertical": require("@icon/DotsVertical").default,
	"vmdi-open-in-new": require("@icon/OpenInNew").default,
	"vmdi-rotate-right": require("@icon/RotateRight").default,
	"vmdi-image": require("@icon/Image").default,
	"vmdi-account-supervisor-circle": require("@icon/AccountSupervisorCircle").default,
	"vmdi-file-document-edit": require("@icon/FileDocumentEdit").default,
	"vmdi-lock-reset": require("@icon/LockReset").default,
	"vmdi-account-edit": require("@icon/AccountEdit").default,
	"vmdi-square-edit-outline": require("@icon/SquareEditOutline").default,
	"vmdi-account-circle": require("@icon/AccountCircle").default,
	"vmdi-chevron-down": require("@icon/ChevronDown").default,
	"vmdi-chevron-up": require("@icon/ChevronUp").default,
	"vmdi-arrow-right": require("@icon/ArrowRight").default,
	"vmdi-numeric-1-box": require("@icon/Numeric1Box").default,
	"vmdi-numeric-2-box": require("@icon/Numeric2Box").default,
	"vmdi-numeric-3-box": require("@icon/Numeric3Box").default,
	"vmdi-numeric-4-box": require("@icon/Numeric4Box").default,
	"vmdi-numeric-5-box": require("@icon/Numeric5Box").default,
	"vmdi-numeric-6-box": require("@icon/Numeric6Box").default,
	"vmdi-numeric-7-box": require("@icon/Numeric7Box").default,
	"vmdi-arrow-collapse-right": require("@icon/ArrowCollapseRight").default,
	"vmdi-arrow-collapse-left": require("@icon/ArrowCollapseLeft").default,
	"vmdi-lastpass": require("@icon/Lastpass").default,
	"vmdi-account-tie": require("@icon/AccountTie").default,
	"vmdi-calendar-month": require("@icon/CalendarMonth").default,
	"vmdi-pencil": require("@icon/Pencil").default,
	"vmdi-archive-arrow-up": require("@icon/ArchiveArrowUp").default,
	"vmdi-format-bold": require("@icon/FormatBold").default,
	"vmdi-format-italic": require("@icon/FormatItalic").default,
	"vmdi-format-strikethrough": require("@icon/FormatStrikethrough").default,
	"vmdi-format-paragraph": require("@icon/FormatParagraph").default,
	"vmdi-format-list-bulleted": require("@icon/FormatListBulleted").default,
	"vmdi-format-list-numbered": require("@icon/FormatListNumbered").default,
	"vmdi-format-header-1": require("@icon/FormatHeader1").default,
	"vmdi-format-header-2": require("@icon/FormatHeader2").default,
	"vmdi-format-header-3": require("@icon/FormatHeader3").default,
	"vmdi-format-header-4": require("@icon/FormatHeader4").default,
	"vmdi-format-header-5": require("@icon/FormatHeader5").default,
	"vmdi-format-header-6": require("@icon/FormatHeader6").default,
	"vmdi-format-quote-close": require("@icon/FormatQuoteClose").default,
	"vmdi-drag-horizontal-variant": require("@icon/DragHorizontalVariant").default,
	"vmdi-format-line-weight": require("@icon/FormatLineWeight").default,
	"vmdi-undo": require("@icon/Undo").default,
	"vmdi-redo": require("@icon/Redo").default,
	"vmdi-file-document-multiple": require("@icon/FileDocumentMultiple").default,
	"vmdi-receipt": require("@icon/Receipt").default,
	"vmdi-arrange-bring-to-front": require("@icon/ArrangeBringToFront").default,
	"vmdi-currency-gbp": require("@icon/CurrencyGbp").default,
	"vmdi-close-box": require("@icon/CloseBox").default,
	"vmdi-checkbox-marked": require("@icon/CheckboxMarked").default,
	"vmdi-image-area": require("@icon/ImageArea").default,
	"vmdi-magnify-plus": require("@icon/MagnifyPlus").default,
	"vmdi-magnify-minus": require("@icon/MagnifyMinus").default,
	"vmdi-crop-rotate": require("@icon/CropRotate").default,
	"vmdi-restart": require("@icon/Restart").default,
	"vmdi-arrow-expand-all": require("@icon/ArrowExpandAll").default,
	"vmdi-minus": require("@icon/Minus").default,
	"vmdi-plus-circle": require("@icon/PlusCircle").default,
};