<template>
  <!-- NAV -->
  <div class="web-nav px-2 h-100" :class="{ 'web-nav--collapsed': collapsed }">
    <div class="p-2 my-1 radius-2 bg-natural-lightest" v-if="collapsed">
      <img src="img/my-farewell-logo-new.png" width="100%" />
    </div>
    <div v-else class="p-3 m-2 radius-2 bg-natural-lightest">
      <img src="img/my-farewell-logo-new.png" width="100%" />
    </div>

    <s-button-group
      class="web-nav__items flex flex--column"
      stack
      size="lg"
      align="left"
      :shape="collapsed ? 'square' : null"
    >
      <s-button
        v-if="userRoles !== 'admin'"
        icon="vmdi-view-dashboard"
        :to="{ name: 'dashboard' }"
        label="Dashboard"
        v-tooltip="tooltip('Dashboard')"
        @click.native="$emit('isMenu', false)"
      />
      <s-button
        v-if="userRoles !== 'admin'"
        icon="vmdi-playlist-edit"
        :to="{ name: 'pre-planning-index' }"
        label="Pre Planning"
        v-tooltip="tooltip('Pre Planning')"
        @click.native="$emit('isMenu', false)"
      />
      <!-- <s-button
        v-if="userRoles !== 'admin'"
        icon="vmdi-flower"
        label="Funerals"
        :to="{ name: 'funerals' }"
        v-tooltip="tooltip('Funerals')"
        @click.native="$emit('isMenu', false)"
      /> -->
      <s-button
        v-if="userRoles == 'admin'"
        icon="vmdi-view-dashboard"
        :to="{ name: 'funeral-directors' }"
        label="Funeral Directors"
        :active="
          $route.name == 'funeral-directors' ||
            $route.name == 'funeral-director-view'
        "
        @click.native="$emit('isMenu', false)"
        v-tooltip="tooltip('Funeral Directors')"
      />
      <s-button
        v-if="userRoles !== 'admin'"
        icon="vmdi-account-group"
        label="Company Profile"
        :to="{ name: 'company' }"
        @click.native="$emit('isMenu', false)"
        v-tooltip="tooltip('Company Profile')"
      />
      <!-- <s-button
        icon="vmdi-file-document"
        label="Notice Template"
        @click.native="$emit('isMenu', false)"
        :to="{ name: 'funeral-notice' }"
        v-tooltip="tooltip('Funeral Notice Template')"
      /> -->
      <!-- <s-button
        v-if="userRoles !== 'admin'"
        icon="vmdi-coffin"
        label="Products"
        :to="{ name: 'products' }"
        @click.native="$emit('isMenu', false)"
        v-tooltip="tooltip('Products')"
      /> -->
      <!-- <s-button
        v-if="userRoles !== 'admin'"
        icon="vmdi-cogs"
        label="Service Fees"
        :to="{ name: 'services' }"
        @click.native="$emit('isMenu', false)"
        v-tooltip="tooltip('Services')"
      /> -->
      <s-button
        v-if="!collapsed"
        :icon="avatar ? '' : 'vmdi-account-circle'"
        v-tooltip="tooltip(userFullName)"
        @click.native="isSlide = !isSlide"
        class="nav-user-profile radius-0 bt mt-3"
      >
        <s-media
          class="user-image radius-4"
          width="20px"
          height="20px"
          :value="avatar"
          v-if="avatar"
        />
        <div class="text-capitalize">
          {{ userFullName }}
        </div>
        <s-icon
          v-if="!isSlide"
          class="ml-auto"
          :size="15"
          name="vmdi-chevron-down"
        />
        <s-icon v-else class="ml-auto" :size="15" name="vmdi-chevron-up" />
      </s-button>
      <s-button
        v-if="collapsed || isSlide"
        icon="vmdi-account-edit"
        :to="{ name: 'edit-profile' }"
        label="Profile"
        v-tooltip="tooltip('Profile')"
        @click.native="$emit('isMenu', false)"
      />
      <!-- <s-button
        v-if="collapsed || (isSlide && userRoles !== 'admin')"
        icon="vmdi-receipt"
        :to="{ name: 'subscription' }"
        label="Subscription"
        v-tooltip="tooltip('Subscription')"
        @click.native="$emit('isMenu', false)"
      /> -->
      <s-button
        v-if="collapsed || isSlide"
        icon="vmdi-lock-reset"
        :to="{ name: 'change-password' }"
        label="Change Password"
        v-tooltip="tooltip('Change Password')"
        @click.native="$emit('isMenu', false)"
      />

      <s-button
        class="mt-auto"
        icon="vmdi-login-variant"
        @click.native="logout"
        label="Logout"
        v-tooltip="tooltip('Logout')"
      />
      <s-button
        @click.native="collapseHandler"
        :icon="
          collapsed ? 'vmdi-arrow-collapse-right' : 'vmdi-arrow-collapse-left'
        "
        label="Collapse"
        class="collapse-button"
      />
    </s-button-group>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  props: {
    isMenu: {
      type: Boolean,
    },
  },
  data() {
    return {
      isSlide: false,
    };
  },
  computed: {
    ...mapState({
      collapsed: (state) => state.account.isAdminNavCollapsed,
    }),
    ...mapGetters(["user", "userFullName", "avatar", "userRoles"]),
  },

  mounted() {
    this.slideHandler();
  },
  methods: {
    ...mapActions({
      setPref: "setIsAdminNavCollapsed",
    }),
    collapseHandler() {
      this.setPref(!this.collapsed);
      this.slideHandler();
    },
    slideHandler() {
      if (
        this.$route.name === "change-password" ||
        this.$route.name === "edit-profile"
      ) {
        this.isSlide = true;
      }
    },
    tooltip(content) {
      return {
        content: content,
        disabled: !this.collapsed,
        delay: { show: 500, hide: 0 },
        placement: "right",
      };
    },
    logout() {
      this.$router.push({
        name: "logout",
      });
    },
  },
};
</script>

<style lang="scss">
.web-nav {
  // background-color: #3d040f;
  background-color: #0f2142;
  grid-column: 1 / 2;
  overflow: auto;
  display: flex;
  flex-direction: column;
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
}
.web-nav--collapsed {
  .button__label {
    display: none;
  }
  .olvy-unread-indicator {
    position: absolute !important;
    bottom: 6px !important;
    right: 6px !important;
    top: auto !important;
  }
}
.web-nav__items {
  flex: 1 1 auto;
  .button {
    //--button--color: rgba(255, 255, 255, 0.3);
    // --button--hover-color: rgba(255, 255, 255, 1);
    //--button--hover-color: #bf9f00;
    --button--color: #777;
    --button--hover-color: #fff;
    flex: 0 0 auto;
  }
  .button--active,
  .button--exact-active {
    position: relative;
    // --button--bg: rgba(255, 255, 255, 0.08);
    // --button--hover-bg: rgba(255, 255, 255, 0.08);
    // color: #fff;
    --button--bg: #fff;
    --button--hover-bg: #fff;
    color: #{--color("primary")};
    --button--hover-color: #{--color("primary")};
  }
  .button--highlight {
    --button--color: #{--color("warning", "light")};
    --button--hover-color: #{--color("warning")};
  }
}
.web-nav__campaign {
  background-color: rgba(255, 255, 255, 0.03);
  color: rgba(255, 255, 255, 0.5) !important;
  text-decoration: none !important;
  &:hover {
    color: rgba(255, 255, 255, 1) !important;
  }
}
.web-nav__campaign__label {
  font-size: 12px;
  margin-bottom: --space(1);
}
.nav-user-profile {
  .button__label {
    display: flex;
    align-items: center;
    flex: 1;
  }
  .user-image {
    margin-right: 10px;
    .media__wrap {
      img {
        border-radius: 50%;
      }
    }
  }
}
</style>
