var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-grey-lightest",class:_vm.editable ? '' : 'media-disabled'},[(_vm.editor)?_c('div',{staticClass:"bb-light p-2"},[_c('s-button',{staticClass:" px-2 text-grey-lightest mr-2",class:_vm.editor.isActive('bold') ? 'bg-primary' : ' bg-grey',attrs:{"size":"sm","theme":"muted","icon":"vmdi-format-bold"},nativeOn:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleBold()
          .run()}}}),_c('s-button',{staticClass:"px-2 text-grey-lightest mr-2",class:_vm.editor.isActive('italic') ? 'bg-primary' : ' bg-grey',attrs:{"size":"sm","theme":"muted","icon":"vmdi-format-italic"},nativeOn:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleItalic()
          .run()}}}),_c('s-button',{staticClass:" px-2 text-grey-lightest mr-2",class:_vm.editor.isActive('strike') ? 'bg-primary' : ' bg-grey',attrs:{"size":"sm","theme":"muted","icon":"vmdi-format-strikethrough"},nativeOn:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleStrike()
          .run()}}}),_c('s-button',{staticClass:"px-2 text-grey-lightest mr-2",class:_vm.editor.isActive('heading', { level: 1 }) ? 'bg-primary' : ' bg-grey',attrs:{"size":"sm","theme":"muted","icon":"vmdi-format-header-1"},nativeOn:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleHeading({ level: 1 })
          .run()}}}),_c('s-button',{staticClass:" px-2 text-grey-lightest mr-2",class:_vm.editor.isActive('heading', { level: 2 }) ? 'bg-primary' : ' bg-grey',attrs:{"size":"sm","theme":"muted","icon":"vmdi-format-header-2"},nativeOn:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleHeading({ level: 2 })
          .run()}}}),_c('s-button',{staticClass:"px-2 text-grey-lightest mr-2",class:_vm.editor.isActive('heading', { level: 3 }) ? 'bg-primary' : ' bg-grey',attrs:{"size":"sm","theme":"muted","icon":"vmdi-format-header-3"},nativeOn:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleHeading({ level: 3 })
          .run()}}}),_c('s-button',{staticClass:"px-2 text-grey-lightest mr-2",class:_vm.editor.isActive('heading', { level: 4 }) ? 'bg-primary' : ' bg-grey',attrs:{"size":"sm","theme":"muted","icon":"vmdi-format-header-4"},nativeOn:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleHeading({ level: 4 })
          .run()}}}),_c('s-button',{staticClass:"px-2 text-grey-lightest mr-2",class:_vm.editor.isActive('heading', { level: 5 }) ? 'bg-primary' : ' bg-grey',attrs:{"size":"sm","theme":"muted","icon":"vmdi-format-header-5"},nativeOn:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleHeading({ level: 5 })
          .run()}}}),_c('s-button',{staticClass:"px-2 text-grey-lightest mr-2",class:_vm.editor.isActive('heading', { level: 6 }) ? 'bg-primary' : ' bg-grey',attrs:{"size":"sm","theme":"muted","icon":"vmdi-format-header-6"},nativeOn:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleHeading({ level: 6 })
          .run()}}}),_c('s-button',{staticClass:"px-2 text-grey-lightest mr-2",class:_vm.editor.isActive('bulletList') ? 'bg-primary' : ' bg-grey',attrs:{"size":"sm","theme":"muted","icon":"vmdi-format-list-bulleted"},nativeOn:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleBulletList()
          .run()}}}),_c('s-button',{staticClass:"px-2 text-grey-lightest mr-2",class:_vm.editor.isActive('orderedList') ? 'bg-primary' : ' bg-grey',attrs:{"size":"sm","theme":"muted","icon":"vmdi-format-list-numbered"},nativeOn:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleOrderedList()
          .run()}}}),_c('s-button',{staticClass:"px-2 text-grey-lightest mr-2",class:_vm.editor.isActive('blockquote') ? 'bg-primary' : ' bg-grey',attrs:{"size":"sm","theme":"muted","icon":"vmdi-format-quote-close"},nativeOn:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleBlockquote()
          .run()}}}),_c('s-button',{staticClass:"bg-grey px-2 text-grey-lightest mr-2",attrs:{"size":"sm","theme":"muted","icon":"vmdi-drag-horizontal-variant"},nativeOn:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .setHorizontalRule()
          .run()}}}),_c('s-button',{staticClass:"bg-grey px-2 text-grey-lightest mr-2",attrs:{"size":"sm","theme":"muted","icon":"vmdi-format-line-weight"},nativeOn:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .setHardBreak()
          .run()}}}),_c('s-button',{staticClass:"bg-grey px-2 text-grey-lightest mr-2 mt-2",attrs:{"size":"sm","theme":"muted","icon":"vmdi-undo"},nativeOn:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .undo()
          .run()}}}),_c('s-button',{staticClass:"bg-grey px-2 text-grey-lightest mr-2 mt-2",attrs:{"size":"sm","theme":"muted","icon":"vmdi-redo"},nativeOn:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .redo()
          .run()}}})],1):_vm._e(),_c('editor-content',{staticClass:"p-3 ",class:_vm.defaultHeight ? 'editor-height' : '',attrs:{"editor":_vm.editor,"disabled":true,"spellcheck":"true"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }