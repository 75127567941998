var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-100"},[(!_vm.isNotDashboard)?_c('portal',{attrs:{"to":"header"}},[_c('s-button',{attrs:{"size":"sm","color":"primary","theme":"primary","label":"Add New","icon":"vmdi-plus","to":{ name: 'pre-planning-welcome' }}})],1):_vm._e(),_c('sp-list',{ref:"list",class:!_vm.isNotDashboard ? 'h-100' : '',attrs:{"endpoint":"/v1/funeral-pre-planning","actions":!_vm.isNotDashboard ? ['refresh', 'settings'] : [''],"footer":!_vm.isNotDashboard,"attrs":_vm.columns,"params":_vm.params,"per-page":10}},[_c('sp-list-table',{on:{"rowClick":function($event){return _vm.editPage($event.uuid)}},scopedSlots:_vm._u([{key:"mobile_no",fn:function(ref){
var item = ref.item;
return [(item.mobile_no)?_c('div',[_vm._v(" "+_vm._s(item.mobile_no)+" ")]):_c('div',{staticClass:"text-xs text-italic text-grey"},[_vm._v(" (No Details Provided) ")])]}},{key:"responsible_name",fn:function(ref){
var item = ref.item;
return [(item.responsible_name)?_c('div',[_vm._v(" "+_vm._s(item.responsible_name)+" ")]):_c('div',{staticClass:"text-xs text-italic text-grey"},[_vm._v(" (No Details Provided) ")])]}},{key:"responsible_email",fn:function(ref){
var item = ref.item;
return [(item.responsible_email)?_c('div',[_vm._v(" "+_vm._s(item.responsible_email)+" ")]):_c('div',{staticClass:"text-xs text-italic text-grey"},[_vm._v(" (No Details Provided) ")])]}},{key:"beneficiary_name",fn:function(ref){
var item = ref.item;
return [(item.beneficiary_name)?_c('div',[_vm._v(" "+_vm._s(item.beneficiary_name)+" ")]):_c('div',{staticClass:"text-xs text-italic text-grey"},[_vm._v(" (No Details Provided) ")])]}},{key:"ceremony_location",fn:function(ref){
var item = ref.item;
return [(item.ceremony_location)?_c('div',[_vm._v(" "+_vm._s(item.ceremony_location)+" ")]):_c('div',{staticClass:"text-xs text-italic text-grey"},[_vm._v(" (No Details Provided) ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }