<template>
  <item-form
    :get="get"
    ref="item"
    class="p-4 box"
    :item="true"
    :save="save"
    width="450px"
    :footer="true"
    :notify="true"
    :fields="fields"
    :redirect="false"
    :breadcrumb="false"
  >
    <template #default="{form}">
      <s-field
        label="Your Ceremony Wishes">
        <s-radio
          class="mt-1"
          :options="ceremonyWishes"
          v-model="form.ceremony_type"
          :key="form.ceremony_type"
        />
      </s-field>
      
      <!-- <s-field-validate
        class="mt-3"
        label="Please detail any other ceremony wishes that you may have"
        rules="max:512"
      >
      <s-textbox
        type="text"
        spellcheck="true"
        v-model="form.ceremony_wishes_other_detail"
      />
      </s-field-validate> -->
      <s-button
      theme="outline"
      color="primary"
      class="button-border"
      icon="vmdi-plus-circle"
      @click.native="isCeremony = !isCeremony"
      v-if="(form.ceremony_type === 'religious') || (form.ceremony_type === 'civil')"
      label="Add Ceremony wishes"
      />

      <div class="ceremony-wish-form" v-if="isCeremony || (form.ceremony_wishes_location)">
        <s-field-validate
          v-if="(form.ceremony_type === 'religious') || (form.ceremony_type === 'civil')"
          label="Your preferred ceremony location: e.g. church/ funeral home/ alternative venue"
          rules="max:64"
        >
        <s-textbox
          type="text"
          spellcheck="true"
          v-model="form.ceremony_wishes_location"
        />
        </s-field-validate>

        <s-field-validate
          class="mt-3"
          v-if="(form.ceremony_type === 'religious') || (form.ceremony_type === 'civil')"
          label="Would you like a specific priest, minister or celebrant to look after the ceremony?"
          rules="max:64"
        >
        <s-textbox
          type="text"
          spellcheck="true"
          v-model="form.ceremony_wishes_celebrant"
        />
        </s-field-validate>

        <!-- <s-field-validate
          class="mt-3"
          v-if="(form.ceremony_type === 'religious')"
          label="Would you like a public or private ceremony wishes?"
          rules="max:64"
        >
        <s-textbox
          type="text"
          spellcheck="true"
          v-model="form.ceremony_wishes_type"
        />
        </s-field-validate> -->

        <s-field-validate
          class="mt-3"
          v-if="(form.ceremony_type === 'religious') || (form.ceremony_type === 'civil')"
          label="Would you like provide any details for the ceremony? (readings/ prayers/ music/ eulogy etc.)"
          rules="max:512"
        >
        <s-textbox
          type="text"
          spellcheck="true"
          v-model="form.ceremony_wishes_any_detail"
        />
        </s-field-validate>

         <s-field-validate
          class="mt-3"
          v-if="(form.ceremony_type === 'religious')"
          label="Would you like to have an evening removal (if available)?"
          rules="max:64"
        >
        <s-textbox
          type="text"
          spellcheck="true"
          v-model="form.evening_removal"
        />
        </s-field-validate>

        <s-field-validate
          class="mt-3"
          v-if="(form.ceremony_type === 'religious') || (form.ceremony_type === 'civil')"
          label="Please detail any other ceremony wishes that you may have"
          rules="max:512"
        >
        <s-textbox
          type="text"
          spellcheck="true"
          v-model="form.ceremony_wishes_other_detail"
        />
      </s-field-validate>
      </div>

      <s-field-validate
          class="mt-3"
          v-if="(form.ceremony_type === 'other')"
          label="Please detail any other ceremony wishes that you may have"
          rules="max:512"
        >
        <s-textbox
          type="text"
          spellcheck="true"
          v-model="form.ceremony_wishes_other_detail"
        />
      </s-field-validate>

     
    </template>
    <template #footer="{saveItem,saving}" :class="['']">
      <s-row class="g-2">
        <s-column :size="4">
          <s-button
            fluid
            label="Back"
            theme="muted"
            @click.native="back"
            class="bg-grey-lighter text-grey"
          />
        </s-column>
        <s-column :size="4">
          <s-button
            fluid
            align="right"
            color="primary"
            :loader="saving"
            label="Next"
            icon="vmdi-arrow-right"
            @click.native="saveItem"
          />
        </s-column>
      </s-row>
    </template>
  </item-form>
</template>
<script>
import { addCeremonyWishes } from "@/api/pre-planning/create";
export default {
  props: {
    prePlanningId: [Number, String],
    perPlanningDetails: Object,
  },
  components: {
    ItemForm: require("@/components/ItemForm").default,
    //PersonalWishes: require("@/components/PersonalWishes").default,
  },
  data() {
    return {
      isCeremony:false,
      fields: [
        // "coffin_choice_wake_viewing",
        // "visitation_wake_viewing",
        // "coffin_type",
        // "coffin_specify",
        // "coffin_cost_type",
        // "coffin_type_wishes",
        // "coffin_open_people_see",
        // "coffin_open_people_wishes",
        // "particular_presentation_request",
        // "coffin_open_for",
        // "wake_visitation_type",
        // "public_wake_visitation_wishes",
        // "public_wake_preferred_location",
        // "public_wake_address",
        // "other_request",
        "ceremony_type",
        "ceremony_wishes_location",
        "ceremony_wishes_type",
        "ceremony_wishes_any_detail",
        "ceremony_wishes_celebrant",
        "ceremony_wishes_other_detail",
        "evening_removal",
      ],
    };
  },
  computed: {
    ceremonyWishes() {
      return [
        { label: "Religious/Traditional", value: "religious" },
        { label: "Civil or Non-religious", value: "civil" },
        { label: "No Ceremony", value: "no_ceremony" },
        { label: "Other(Please Specify)", value: "other" },
      ];
    },
  
  },
  methods: {
    get() {
      return { ...this.perPlanningDetails };
    },
    async save(id, data) {
      data["prePlanningId"] = this.prePlanningId;
      return addCeremonyWishes(data).then((res) => {
        this.$emit("refresh");
        this.$router.push({ name: "view-visitation-details" });
      });
    },
    back() {
      this.$router.push({ name: "per-planning-cremation" });
    },
  },
};
</script>
<style lang="scss" scoped>
.button-border {
  border: 1px dashed var(--color--primary) !important;
}
</style>
