<template>
  <s-layout
    full-height
    right-size="350px"
    :right-classlist="['p-3', 'bl-lightest']"
  >
    <template #default>
      <item-form
        :item="true"
        :fields="fields"
        :save="save"
        :get="get"
        class="p-4"
        :footer="true"
        :breadcrumb="false"
        :redirect="false"
        width="710px"
        ref="item"
        :notify="true"
      >
        <template #default="{form}">
          <s-field-validate label="Title" rules="required">
            <!-- <s-textarea rows="5" v-model="form.banner_title" type="text" /> -->
            <editor v-model="form.banner_title" :default-height="false" />
          </s-field-validate>
          <s-field-validate label="Description" rules="required">
            <!-- <s-textarea rows="12" v-model="form.banner_description" /> -->
            <editor v-model="form.banner_description" />
          </s-field-validate>
        </template>
      </item-form>
    </template>
    <template #right>
      <div class="text-grey mb-2 text-md tags-card">
        Description Form Fields
      </div>
      <div v-if="tagsData">
        <card
          :title="tag.category"
          v-for="(tag, index) in tagsData"
          :key="index"
          :values="tag.fields"
        />
      </div>
    </template>
  </s-layout>
</template>
<script>
import company from "@/api/company";
import { getTags } from "@/api/templateContent";
import { mapGetters } from "vuex";

export default {
  props: {
    businessDetails: [Object],
  },
  components: {
    ItemForm: require("@/components/ItemForm").default,
    Editor: require("@/components/Editor").default,
    Card: require("@/views/notice/Card").default,
  },
  computed: {
    ...mapGetters(["business"]),
  },
  data() {
    return {
      fields: ["banner_title", "banner_description"],
      loader: false,
      tagsData: null,
    };
  },
  created() {
    getTags({ slug: "welcome-screen" }).then((res) => {
      this.tagsData = res.tags;
    });
  },
  methods: {
    get() {
      return this.business;
    },
    async save(id, data) {
      return company.upsertBanner(this.business?.id, data).then((res) => {
        this.$root.$emit("rehydrate");
        return res;
      });
    },
  },
};
</script>
<style scoped>
.tags-card {
  margin-top: 60%;
}
</style>
