import Vue from "vue";
import "./vee-validate";

require("./shilp-vue");

/**
 * Popover
 */
import vTooltip from "v-tooltip";
Vue.use(vTooltip, {
    defaultBoundariesElement: document.body,
});


import * as VueFloatPreview from 'vue-float-preview';
Vue.use(VueFloatPreview);

import VueSilentbox from 'vue-silentbox'
Vue.use(VueSilentbox)

import VCalendar from 'v-calendar';
// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
    componentPrefix: 'vc',
    popoverVisibility: 'focus' // Use <vc-calendar /> instead of <v-calendar />
});

/**
 * Cropper
 */
import VueCropper from "vue-cropperjs";
import 'cropperjs/dist/cropper.css';
Vue.component("vue-cropper", VueCropper);

/**
 * Portal
 */
import PortalVue from "portal-vue";
Vue.use(PortalVue);
