import axios from "@/plugins/axios";

export const cratePrePlanningId = async (data) => {
  return axios.post(`/v1/funeral-pre-planning`, data).then((res) => {
    return res.data;
  });
};

export const getDetails = async (data) => {
  return axios
    .get(`/v1/funeral-pre-planning/${data.prePlanningId}`)
    .then((res) => {
      return res.data;
    });
};

export const addIntroduction = async (data) => {
  return axios
    .put(
      `/v1/funeral-pre-planning/${data.prePlanningId}/introduction-detail`,
      data
    )
    .then((res) => {
      return res.data;
    });
};

export const addFuneralTypes = async (data) => {
  return axios
    .put(`/v1/funeral-pre-planning/${data.prePlanningId}/type-detail`, data)
    .then((res) => {
      return res.data;
    });
};

export const addVisitationDetails = async (data) => {
  return axios
    .put(
      `/v1/funeral-pre-planning/${data.prePlanningId}/visitation-detail`,
      data
    )
    .then((res) => {
      return res.data;
    });
};

export const addCeremonyWishes = async (data) => {
  return axios
    .put(`/v1/funeral-pre-planning/${data.prePlanningId}/ceremony-wishes`, data)
    .then((res) => {
      return res.data;
    });
};

export const addFuneralTransport = async (data) => {
  return axios
    .put(
      `/v1/funeral-pre-planning/${data.prePlanningId}/transport-detail`,
      data
    )
    .then((res) => {
      return res.data;
    });
};

export const addSpecialRequest = async (data) => {
  return axios
    .put(`/v1/funeral-pre-planning/${data.prePlanningId}/special-request`, data)
    .then((res) => {
      return res.data;
    });
};

export const addPersonalWishes = async (data) => {
  return axios
    .put(`/v1/funeral-pre-planning/${data.prePlanningId}/personal-wishes`, data)
    .then((res) => {
      return res.data;
    });
};

export const addConfirmation = async (data) => {
  return axios
    .put(
      `/v1/funeral-pre-planning/${data.prePlanningId}/confirm-pre-planning`,
      data
    )
    .then((res) => {
      return res.data;
    });
};
