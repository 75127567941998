<template>
  <item-form
    :get="get"
    ref="item"
    class="p-4 box"
    :item="true"
    :save="save"
    width="500px"
    :footer="true"
    :notify="true"
    :fields="fields"
    :redirect="false"
    :breadcrumb="false"
    :form-state.sync="formState"
  >
    <template #default="{form}">
      <!-- <div class="mb-2 text-primary">
        Every funeral is personal and unique. You can personalise your plan by
        including additional services. Once you have created your plan you can
        add to or change your chosen services.
      </div>
      <div class="text-italic text-grey mb-2">
        Please note this is purely a guideline for your funeral arrangements.
      </div> -->
      <s-field-validate label="Choice of Funeral Service Type" rules="required">
        <s-radio
          class="mt-1"
          v-model="form.funeral_type"
          :options="funeralTypeOptions"
        />
      </s-field-validate>
      

      <!-- Burial content: Start -->
      <s-button
      theme="outline"
      color="primary"
      class="button-border"
      icon="vmdi-plus-circle"
      @click.native="isBurial = !isBurial"
      v-if="typeBuried"
      label="Add Burial wishes"
    />

    <div class="burial-form" v-if="isBurial || form.burial_location">
      <s-field-validate class="mb-3" v-if="typeBuried" label="Your preferred cemetery location:" rules="max:64">
        <s-textbox
          type="text"
          spellcheck="true"
          v-model="form.burial_location"
        />
      </s-field-validate>

      <s-field class="mb-3"
        v-if="typeBuried"
        label="Do you have permission to use an existing grave/burial plot ?"
      >
        <s-radio
          :options="yesNoOptions"
          class="mt-1"
          v-model="form.particular_grave"
        />
      </s-field>
      
        <s-field-validate class="mb-3"
        rules="max:1024"
        v-if="typeBuried"
        label="Please provide any details you have if an existing plot is available (location, reference, size, preferred opening location)">
        <s-textarea
          type="text"
          spellcheck="true"
          v-model="form.particular_grave_detail"  
        />
      </s-field-validate>

      <!-- <s-field-validate v-if="typeBuried" class="mb-3" label="Would you like a public or private burial ceremony?" rules="max:16">
        <s-textbox
          type="text"
          spellcheck="true"
          v-model="form.type"
        />
      </s-field-validate> -->

      <s-field-validate
        class="mb-3"
        v-if="typeBuried"
        label="Would you like a public or private burial ceremony?">
        <s-radio
          class="mt-1"
          :options="typeBurialOption"
          v-model="form.type"
        />
        </s-field-validate>

      <!-- <s-field-validate class="mb-3"
        rules="max:64"
        v-if="typeBuried"
        label="I would like my ashes to be placed in the following location"
      >
        <s-textbox
          type="text"
          spellcheck="true"
          v-model="form.ashes_placed_location"
        />
      </s-field-validate> -->

      <s-field-validate class="mb-3"
        rules="max:512"
        v-if="typeBuried"
        label="Would you like to describe any works that you would like to organise after your burial to the burial plot? (e.g. additional headstone inscription/ new headstone/ temporary grave markers)">
        <s-textbox
          type="text"
          spellcheck="true"
          v-model="form.burial_service_work"
        />
      </s-field-validate>

      <s-field-validate class="mb-3"
        rules="max:512"
        v-if="typeBuried"
        label="Please detail any other burial wishes that you may have"
      >
        <s-textbox
          type="text"
          spellcheck="true"
          v-model="form.burial_other_detail"
        />
      </s-field-validate>

    </div>

    <!-- Burial content: End -->

    <!-- Cremation content: Start -->

    <s-button
      theme="outline"
      color="primary"
      class="button-border"
      icon="vmdi-plus-circle"
      @click.native="isCremation = !isCremation"
      v-if="typeCremated"
      label="Add Cremation wishes"
    />
      
    <div class="cremation-form" v-if="isCremation || form.cremarotium_location">
      <s-field-validate class="mb-3" v-if="typeCremated" label="Your preferred crematorium location:" rules="max:64">
        <s-textbox
          type="text"
          spellcheck="true"
          v-model="form.cremarotium_location"
        />
      </s-field-validate>

      
      <s-field class="mb-3"
        v-if="typeCremated"
        label="Would you like a ceremony to be held at the crematorium?">
        <s-radio
            :options="yesNoOptions"
            class="mt-1"
            v-model="form.like_ceremony"
          />
      </s-field>

      
        <!-- <s-field-validate v-if="form.like_ceremony === 'yes' && (typeCremated)" class="mb-3" label="Would you like a public or private cremation ceremony?" rules="max:16">
          <s-textbox
            type="text"
            spellcheck="true"
            v-model="form.type"
          />
        </s-field-validate> -->

        <s-field-validate
        class="mb-3"
        v-if="form.like_ceremony === 'yes' && (typeCremated)"
        label="Would you like a public or private cremation ceremony?">
        <s-radio
          class="mt-1"
          :options="typeCremationOption"
          v-model="form.type"
        />
        </s-field-validate>

        <s-field-validate class="mb-3"
          rules="max:128" v-if="form.like_ceremony === 'yes' && (typeCremated)"
          label="Would you like to describe music for the cremation ceremony?"
        >
          <s-textbox
            type="text"
            spellcheck="true"
            v-model="form.cremation_music"
          />
        </s-field-validate>

        <s-field-validate class="mb-3"
          rules="max:64"
          v-if="form.like_ceremony === 'yes' && (typeCremated)"
          label="Would you like a specific priest, minister or celebrant to look after the ceremony?"
        >
          <s-textbox
            type="text"
            spellcheck="true"
            v-model="form.celebrant_master_of_ceremonies"
          />
        </s-field-validate>

        <s-field-validate class="mb-3"
          rules="max:100"
          v-if="form.like_ceremony === 'yes' && (typeCremated)"
          label="Please detail any other cremation ceremony wishes that you may have">
          <s-textbox
            type="text"
            spellcheck="true"
            v-model="form.cremation_other_detail"
          />
        </s-field-validate>

        <s-field-validate 
        v-if="form.like_ceremony === 'yes' && (typeCremated)"
         label="Would you like to describe how you would like your ashes to be looked after when they are returned to your family?">
        <s-radio
          class="mb-3"
          v-model="form.ashes_process"
          :options="yesNoAshesOptions"
        />
        </s-field-validate>

        <s-field-validate v-if="form.ashes_process === 'yes' && (typeCremated) && form.like_ceremony === 'yes'" 
        class="mb-3" label="Location/ locations to scatter your ashes" rules="max:64">
          <s-textbox
            type="text"
            spellcheck="true"
            v-model="form.location_scatter_ashes"
          />
        </s-field-validate>

        <s-field-validate v-if="form.ashes_process === 'yes' && (typeCremated) && form.like_ceremony === 'yes'" 
        class="mb-3" label="Location/ locations to bury your ashes" rules="max:64">
          <s-textbox
            type="text"
            spellcheck="true"
            v-model="form.location_bury_ashes"
          />
        </s-field-validate>

        <s-field class="mb-3"
          v-if="form.ashes_process === 'yes' && (typeCremated) && form.like_ceremony === 'yes'"
          label="Stored in a cremation wall/ columbarium Wall">
          <s-radio
              :options="yesNoStoredOptions"
              class="mt-1"
              v-model="form.stored_in_wall"
            />
        </s-field>

        <s-field-validate v-if="form.ashes_process === 'yes' && (typeCremated) && form.like_ceremony === 'yes'" 
        class="mb-3" label="Alternative storage/ internment location" rules="max:528">
          <s-textbox
            type="text"
            spellcheck="true"
            v-model="form.ashes_alternative_location"
          />
        </s-field-validate>


    </div>

    <!-- Cremation content: End -->
      
    </template>

    <template #footer="{saveItem,saving}" :class="['']">
      <s-row class="g-2">
        <s-column :size="4">
          <s-button
            fluid
            label="Back"
            theme="muted"
            @click.native="back"
            class="bg-grey-lighter text-grey"
          />
        </s-column>
        <s-column :size="4">
          <s-button
            fluid
            align="right"
            color="primary"
            :loader="saving"
            label="Next"
            icon="vmdi-arrow-right"
            @click.native="saveItem"
          />
        </s-column>
      </s-row>
    </template>
  </item-form>
</template>
<script>
import { addFuneralTypes } from "@/api/pre-planning/create";

export default {
  props: {
    prePlanningId: [Number, String],
    perPlanningDetails: Object,
  },
  components: {
    ItemForm: require("@/components/ItemForm").default,
    //PersonalWishes: require("@/components/PersonalWishes").default,
  },
  data() {
    return {
      formState: null,
      isBurial:false,
      isCremation:false,
      // yesNoAshesOptions:[
      //   { label: "Yes", value: "yes" },
      //   { label: "No", value: "no" },
      // ],
      // yesNoStoredOptions:[
      //   { label: "Yes", value: "yes" },
      //   { label: "No", value: "no" },
      // ],
    };
    
  },
  computed: {
    fields() {
      return [
        { name: "funeral_type", value: "burial" },
        "type",
        "cremarotium_location",
        "burial_location",
        //"preferred_cemetery_wishes",
        "ashes_placed_location",
        "ashes_placed_location_wishes",
        "particular_grave",
        "particular_grave_detail",
        //"particular_grave_wishes",
        //"particular_cemetery",
        //"partuclar_cemetery_wishes",
        // "ceremony_type",
        // "ceremony_type_wishes",
        //"ceremony_location",
        //"ceremony_location_wishes",
        "burial_service_work",
        "burial_other_detail",
        "like_ceremony",
        "type",
        "cremation_music",
        "celebrant_master_of_ceremonies",
        "ashes_process",
        "cremation_other_detail",
        "location_scatter_ashes",
        "location_bury_ashes",
        "stored_in_wall",
        "ashes_alternative_location",
      ];
    },
    funeralTypeOptions() {
      return [
        { label: "Burial", value: "burial" },
        { label: "Cremation", value: "cremation" },
        { label: "Not sure", value: "not_sure" },
        { label: "Let my family decide", value: "family_or_funeral_arranger" },
      ];
    },
    yesNoOptions() {
      return [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ];
    },
    typeCremationOption(){
      return [
        { label: "Public", value: "public" },
        { label: "Private", value: "private" },
      ];
    },
    typeBurialOption(){
      return [
        { label: "Public", value: "public" },
        { label: "Private", value: "private" },
      ];
    },
    // yesNoOptions2() {
    //   return [
    //     { label: "Yes", value: "y" },
    //     { label: "No", value: "n" },
    //   ];
    // },
    yesNoAshesOptions() {
      return [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ];
    },
    yesNoStoredOptions() {
      return [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ];
    },
    // ceremonyOptions() {
    //   return [
    //     { label: "Religious", value: "religious" },
    //     { label: "Civil", value: "civil" },
    //     { label: "Elements of Both", value: "all_of_above" },
    //   ];
    // },
    typeNotSure() {
      if (this.formState?.funeral_type === "not_sure") {
        return true;
      }
      return false;
    },
    typeCremated() {
      if (this.formState?.funeral_type === "cremation") {
        return true;
      }
      return false;
    },
    typeBuried() {
      if (this.formState?.funeral_type === "burial") {
        return true;
      }
      return false;
    },
    likeCeremonyNo(){
      if (this.formState?.like_ceremony === "no") {
        return true;
      }
      return false;
    },
    likeCeremonyYes(){
      if (this.formState?.like_ceremony === "Yes") {
        return true;
      }
      return false;
    },
    likeAshesNo(){
      if (this.formState?.ashes_process === "no") {
        return true;
      }
      return false;
    },
    likeAshesYes(){
      if (this.formState?.ashes_process === "Yes") {
        return true;
      }
      return false;
    },
  },
  watch:{
    'formState.type'(newVal){
      if (newVal !== null) {
        this.formState.like_ceremony ="yes";  
      } 
      else{
        this.formState.like_ceremony ="no";  
      }
    },
    'formState.like_ceremony'(newVal){
      if (newVal == 'no'){
        this.formState.type = null; 
        this.formState.like_ceremony ="no";
      } 
    },
    
  },
  methods: {
    // isBurial() {
    //   if (this.wishes) {
    //     this.$emit("input", "");
    //   }
    //   this.wishes = !this.wishes;
    // },
    get(id) {
      return {
        ...this.perPlanningDetails,
        particular_cemetery: this.perPlanningDetails == "yes" ? "y" : "n",
        
      };
    },
    save(id, data) {
      data["prePlanningId"] = this.prePlanningId;
      data["particular_cemetery"] =
        data["particular_cemetery"] == "y" ? "yes" : "no";
      return addFuneralTypes(data).then((res) => {
        this.$emit("refresh");
        this.$router.push({ name: "my-ceremony-wishes" });
      });
    },
    back() {
      this.$router.push({ name: "pre-planning-introduction" });
    }
  },
};
</script>
<style lang="scss" scoped>
.button-border {
  border: 1px dashed var(--color--primary) !important;
}
</style>
