<template>
  <item-form
    :gap="3"
    :get="get"
    ref="item"
    class="p-4 mt-3 box"
    :item="true"
    :save="save"
    width="400px"
    :footer="true"
    :notify="true"
    :fields="fields"
    :redirect="false"
    :breadcrumb="false"
    :form-state.sync="formState"
  >
    <template #default="{form}">
      <!-- <div class="text-primary">
        <h4>What happens next?</h4>
      </div>
      <div class="text-left">
        <ol class="text-left pl-3">
          <li>
            <p>My Farewell Wishes will post and email you with a <strong>Membership Type 1 Pack</strong> which will include;
              <ul class="pl-4">
                <li class="mt-3">Record of your Wishes</li>
                <li class="mt-3">If requested below, a Membership Type 2 Quotation – Present Day Funeral Cost Quotation</li>
                <li class="mt-3">If requested below, Membership Type 3 Quotation – Fixed Term Cost Agreement Quotation</li>
              </ul>
            </p>
          </li>
          <li class="mt-4">
            <p>My farewell Wishes will then contact you to see if you have any questions or changes to your Record of Wishes.</p>
          </li>
          <li class="mt-4">
            <p>My Farewell Wishes will send a copy of your Record of Wishes to your nominated funeral director and nominated representative</p>
          </li>
        </ol>
      </div> 
      <div class="text-left">
        <h4>Confirmation and Declaration</h4>
        <p class="mt-3">Your nominated representative will be issued with confirmation that they have been named to make sure that your Farewell Wishes are carried out.</p>
        <div class="mt-3">
          <s-field-validate  rules="required"  label="Would you like this representant to get the full details of the record of your wishes?">
            <s-radio class="mt-1" :options="representantRecordOfYourWishes" v-model="form.representant_record_of_your_wishes" />
          </s-field-validate>
          <s-field-validate rules="required" class="mt-4 mt-md-15" label="I would like to get a Membership Type 2 Quotation – Present Day Funeral Cost with payment options">
            <s-radio class="mt-1" :options="presentDayFuneralCost" v-model="form.present_day_funeral_cost" />
          </s-field-validate>
          <s-field-validate rules="required" class="mt-4 mt-md-15" label="I would like to get a Membership Type 3 Quotation – Fixed Term Cost Agreement with payment options">
            <s-radio class="mt-1" :options="fixedTermCost" v-model="form.fixed_term_cost" />
          </s-field-validate>
        </div>
        <div class="mt-4 p-4" style="border: 1px solid #000">
          <p>I wish to confirm that, these are my own wishes.</p>
          <p class="mt-3">I understand that the wishes set out in this document do not form a contract at this stage, and that these details will be used to prepare an application for membership of My Farewell Wishes.
          </p>
          <p class="mt-3">I understand that the information I am supplying may be classed as sensitive under Data Protection laws.</p>
          <p class="mt-3">I understand that this information will only be shared for essential purposes and I consent to this.</p>
          <p class="mt-3">I understand that I can withdraw my consent at any time.</p>
          <s-field-validate class="mt-4">
            <s-radio id="can_withdraw_consent"  :options="canWithdrawConsent" v-model="form.can_withdraw_consent" vid="Verified" name="Verified" />
          </s-field-validate>
        </div>
      </div>-->
      <div>
        <!-- <h4 class="text-center text-uppercase">Confirmation and Declaration</h4> -->
        <h4 class="text-center text-uppercase">CONFIRMATION OF WISHES</h4>
        <div class="mt-3 confirmation">
          <div class="confirmation__wrap">
            <div class="confirmation__text">
              <p class="mb-2">I would like to record my wishes ONLY</p>
            </div>
            <div class="choice">
              <input type="radio" id="_vie21ww3u" value="simple_plan" v-model="form.plan">
              <label for="_vie21ww3u" class="choice__label choice__label--checked">Record Wishes Only</label>
            </div>
          </div>
          <p class="mb-2 text-center"><strong>OR</strong></p>
          <div class="confirmation__wrap">
            <div class="confirmation__text">
              <p class="mb-2">I would like to record my wishes and get a quotation for my funeral</p>
            </div>
            <div class="choice">
              <input type="radio" id="_lljsobcvu" value="plan_and_pay" v-model="form.plan">
              <label for="_lljsobcvu" class="choice__label">Record Wishes With Quote</label>
            </div>
          </div>
            <!-- <div class="choice">
              <s-field-validate
              vid="I would like to record my wishes ONLY"
              name="I would like to record my wishes ONLY"
              >
              <s-radio
                v-model="form.plan"
                :options="simplePlan"
              />
            </s-field-validate> -->
        </div>
        <div class="declaration">
          <!-- <h4 class="declaration-title">Member Declaration</h4> -->
          <h4 class="declaration-title">DATA PROTECTION</h4>
          <div class="declaration-wrap">
            <h4 class="text-uppercase text-primary mt-3">Declaration of member or their representative</h4>

            <!-- <s-field-validate class="member--check mt-3" vid="Declaration of member" name="Declaration of member">
            <s-checkbox
              class="choice"
              id="declaration-of-member"
              v-model="declaration_member" key="formState"
            />
            <label>I, <strong :class="formState.plan_for_you == 'no' ? 'is-dash-active' : ''">{{form.beneficiary}}</strong>, would like to save these wishes for future use by Kieran Bros. Funeral Care.</label>
            </s-field-validate> -->
            
            <div class="plan-label">
              <p>I, <strong :class="formState.plan_for_you == 'no' ? 'is-dash-active' : ''" class="member-name">{{form.beneficiary}}</strong>, would like to save these wishes for future use by Kieran Bros. Funeral Care.</p>
              <span :class="(formState.plan == 'simple_plan' || 'plan_and_pay' ? 'is-plan-active' : '')"></span>
            </div>

          </div>
          <div class="declaration-wrap">
            <!-- <h4 class="text-uppercase text-primary mt-3">Simply Plan</h4> -->
            <h4 class="text-uppercase text-primary mt-3">RECORD WISHES ONLY</h4>
            <div class="plan-label">
              <!-- <p>If I have chosen 'SIMPLY PLAN' above, I understand that recording of my wishes does not form a contracr and the executor of my estate will ultimately be responsible for organising my funeral.</p> -->
              <p>If I have chosen 'Record Wishes Only' above, I understand that my data will be recorded for essential purposes only and I consent to this.</p>
              <span :class="formState.plan == 'simple_plan' ? 'is-plan-active' : ''"></span>
            </div>
          </div>
          <div class="declaration-wrap">
            <!-- <h4 class="text-uppercase text-primary mt-3">Plan and pay</h4> -->
            <h4 class="text-uppercase text-primary mt-3">RECORD WISHES WITH QUOTE</h4>
            <div class="plan-label">
              <!-- <p>If I have chosen 'PLAN AND PAY' above, I understand that payments made will contribute towards the cost of my funeral. I understand that funeral directors' costs may increase over time. I also understand that any future increase in the cost of third-party costs cannot be predicted and is beyond the funeral director.</p> -->
              <p>If I have chosen 'Record Wishes With Quote' above, I understand that Kieran Bros. Funeral Care will use this information to provide a quotation.</p>
              <span :class="formState.plan == 'plan_and_pay' ? 'is-plan-active' : ''"></span>
            </div>
            <div class="plan-label">
              <!-- <p>I understand that my estate will be responsible for the shortfall (the difference between the amount paid and the cost of my funeral arrangements) at the time of my funeral.</p> -->
              <p>I understand that this information will only be shared for essential purposes and I consent to this. </p>
              <span :class="formState.plan == 'plan_and_pay' ? 'is-plan-active' : ''"></span>
            </div>
            <div class="plan-label">
              <!-- <p>I understand that this information will only be shared for essential purpose and I consent to this. I understand that I can withdraw my consent at any time.</p> -->
              <p>I understand that I can withdraw my consent at any time.</p>
              <span :class="formState.plan == 'plan_and_pay' ? 'is-plan-active' : ''"></span>
            </div>
          </div>
          <!-- <div class="declaration-confirm mt-4">
            <s-field-validate class="fields-plan" label="Signed" rules="max:64">
              <s-textbox v-model="form.signed" type="text" spellcheck="true" />
            </s-field-validate>
            <s-field-validate class="fields-plan" label="Print Name" rules="max:64">
              <s-textbox v-model="form.print_name" type="text" spellcheck="true" />
            </s-field-validate>
            <s-field-validate class="fields-plan" label="Confirm Date" rules="max:64">
              <s-textbox v-model="form.confirm_date" type="text" spellcheck="true" />
            </s-field-validate>
          </div> -->
          
        </div>
      </div>
     

    </template>
    <template #footer="{ saveItem, saving }" :class="['']">
        <div>
          <s-field-validate vid="Verified" name="Verified">
            <!-- <s-checkbox
              class="choice"
              id="check-data"
              label="Please tick here to confirm that you understand that disbursments/
third party fees are seperate to funeral director fees and are not within
the funeral directors direct control."
              v-model="isCheck"
            /> -->
          </s-field-validate>
          <s-row class="mt-3">
            <s-column :size="12">
              <s-button
                fluid
                align="center"
                label="Save My Wishes"
                color="primary"
                :loader="saving"
                
                @click.native="saveItem"
              />
            </s-column>
          </s-row>
        </div>
      </template>
    
  </item-form>
  
</template>
<script>
import { addConfirmation } from "@/api/pre-planning/create";
export default {
  props: {
    prePlanningId: [Number, String],
    perPlanningDetails: Object,
    companySlug:String
  },
  components: {
    ItemForm: require("@/components/ItemForm").default,
  },
  data() {
    return {
      formState: null,
      fields:[
        "representant_record_of_your_wishes",
        "present_day_funeral_cost",
        "fixed_term_cost",
        "can_withdraw_consent",
        "plan",
        "plan_and_pay",
        "beneficiary",
        "plan_for_you",
        "isCheck",
        {name: "declaration_member", value:"true"},
        {name:"plan", value:"simple_plan"},
      ],
      planForOptions: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ],
      representantRecordOfYourWishes:[
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ],
      presentDayFuneralCost:[
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ],
      fixedTermCost:[
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ],
      canWithdrawConsent:[
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ],
      simplePlan:[
        {
          label: "SIMPLY  PLAN",
          value: "simple_plan",
        },
        {
          label: "PLAN AND PAY",
          value: "plan_and_pay",
        },
      ],
      isCheck:false,
      //can_withdraw_consent: false,
      
    };
  },
  computed:{
    // isCheck: function(){
    //   return this.formState?.can_withdraw_consent == "yes" ? false : true;
    // },
  },
  
  methods: {
    get() {
      console.log("get",this.perPlanningDetails.status);
      if (this.perPlanningDetails.plan_for_you == 'yes') {
        this.perPlanningDetails.beneficiary = this.perPlanningDetails.name;
      } else {
        this.perPlanningDetails.beneficiary = this.perPlanningDetails.beneficiary_name ? this.perPlanningDetails.beneficiary_name : '-' ;
      }
      this.isCheck = this.perPlanningDetails.status == 'completed' ? true : false
      return this.perPlanningDetails;
    },
    save(id, data) {
      data["prePlanningId"] = this.prePlanningId;
      return addConfirmation(data).then((res) => {
        if (this.companySlug) {
          this.$router.push({
            name: "pre-planning-thank-you",
          });
        } else {
          this.$router.push({ name: "pre-planning-index" });
          //this.$router.push({ name: "thank-you" });
        }
        this.$emit("refresh");
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.confirmation__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.confirmation{
  .choice{
    justify-content: space-between;
  }
}
.declaration{
  border: 1px solid #172c54;
  padding: 20px;
  margin-top: 30px;
  .checkbox {
    margin-top: 10px;
  }
}
.declaration-title{
  background-color: #172c54;
  color: #fff;
  text-align: center;
  padding: 10px;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.declaration label.choice__label {
  flex-direction: row-reverse;
}

.plan-label{
  display:flex;
  margin-top:10px;
  align-items: flex-start;
  justify-content: space-between;
  span{
    margin-left:20px;
    display:block;
    width:18px;
    flex:0 0 18px;
    height:18px;
    border-radius: 4px;
    border: 2px solid var(--field--border-color, var(--color--grey--light));
    margin-right:10px;
    position: relative;
    cursor: not-allowed;
    &.is-plan-active::before{
      content:"";
      background-image:url("data:image/svg+xml;utf8,%3Csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20style=%22width:24px;height:24px%22%20viewBox=%220%200%2024%2024%22%3E%3Cpath%20fill=%22%23ffffff%22%20d=%22M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z%22%20/%3E%3C/svg%3E");
      padding: 2px;
      background-color: var(--field--color, var(--color--primary));
      border-color: var(--field--color, var(--color--primary));
      width: 18px;
      height: 18px;
      margin-right: 8px;
      border: none;
      display: flex;
      border-radius: 4px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 10px;
      position: absolute;
      top: -2px;
      left: -1px;
    }
  }
}
  @media screen and (max-width: 991px){
    .mt-md-15{
      margin-top: 15px !important;
    }
  }
  @media screen and (max-width: 767px){
    .confirmation__wrap{
      display: block;
    }
  }
</style>