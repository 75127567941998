<template>
  <div>
    <item-form
      :get="get"
      ref="item"
      class="p-4 box"
      :item="true"
      :save="save"
      width="500px"
      :notify="true"
      :footer="true"
      :fields="fields"
      :redirect="false"
      :breadcrumb="false"
      :form-state.sync="formState"
    >
      <template #default="{ form }">
        <s-field-validate
          vid="Funeral notice"
          name="Funeral notice"
          label="Do you wish to have a funeral notice?"
          rules="required"
        >
          <s-radio
            class="mt-2"
            v-model="form.funeral_notice"
            :options="funeralNoticeOptions"
          />
        </s-field-validate> 

        <s-button
          theme="outline"
          color="blue"
          class="button-border"
          icon="vmdi-plus-circle"
          @click.native="isFuneralNotice = !isFuneralNotice"
          v-if="form.funeral_notice === 'yes'"
          label="Add Personal wishes"
        />

        <div v-if="isFuneralNotice || form.notice_web || form.notice_newspaper || form.notice_radio || form.notice_social_media || form.funeral_notice_instruction" class="ml-4 notice-details">
          <div class="notice-checkbox">
            <s-field-validate class="mr-3" vid="Online notice" name="Online notice">
            <s-checkbox
              class="choice"
              id="online-notice"
              label="Online Notice"
              v-model="form.notice_web"
            />
            </s-field-validate>
            <s-field-validate class="mr-3" vid="Newspaper" name="Newspaper">
              <s-checkbox
                class="choice"
                label="Newspaper"
                v-model="form.notice_newspaper"
                id="newspaper-notice"
              />
            </s-field-validate>
            <s-field-validate class="mr-3" vid="radio" name="radio">
                <s-checkbox
                  class="choice"
                  label="Radio"
                  v-model="form.notice_radio"
                  id="radio-notice"
                />
            </s-field-validate>
            <s-field-validate class="mr-3" vid="notice social media" name="notice social media">
                <s-checkbox
                  class="choice"
                  label="Social media"
                  v-model="form.notice_social_media"
                  id="notice_social_media"
                />
            </s-field-validate>
          </div>

          <div class="mt-4">
            <s-field-validate
              vid="Photograph with your funeral notice"
              name="Photograph with your funeral notice"
              label="Would you like to have photograph with your funeral notice?">
              <s-field>
                <s-radio
                  v-model="photo"
                  class="choice--stack"
                  :options="onlineOptions"
                />
              </s-field>
              <s-field
                class="mt-2 ml-4"
                style="width: 100px"
                v-if="photo == 'with_photo'"
                >
                <s-media
                  ratio="1x1"
                  width="100px"
                  class="radius-3"
                  :readonly="false"
                  :changeable="false"
                  value-type="base64" 
                  @remove="
                    form.image = null;
                    image = null;

                  "
                  accept=".jpg,.jpeg,.png"
                  :max-file-size="5 * 1024 * 1024"
                  :value="form.image ? form.image : image"
                  @input="openCropper('image', $event, NaN)"
                />
              </s-field>
            </s-field-validate>
          </div>

          <s-field-validate
            class="mt-3"
            vid="Donations to a charity"
            name="Donations to a charity"
            label="Would you like to specify any donations to a charity of your choice?">
            <s-radio
              class="mt-2"
              v-model="form.is_donation_charity"
              :options="isDonationCharity"
            />
          </s-field-validate>

          <s-field-validate class="mt-3 ml-3"
            rules="max:64" v-if="form.is_donation_charity === true"
            label="Name of the charity/ charities of your choice">
            <s-textbox
              type="text"
              spellcheck="true"
              v-model="form.donation_charity"
            />
          </s-field-validate>

          <s-field-validate class="mt-3"
            rules="max:128"
            label="Please detail any other wishes for your funeral notice (e.g. family mentions, funeral cortege route)">
            <s-textbox
              type="text"
              spellcheck="true"
              v-model="form.funeral_notice_instruction"
            />
          </s-field-validate>
        </div>

        <h4 class="text-primary">Floral Tributes</h4>

        <s-field-validate
          vid="Specific details funeral notice"
          name="Specific details funeral notice"
          label="Do you wish to give details of funeral flowers?"
        >
          <s-radio
            class="mt-2"
            v-model="form.specific_funeral_notice"
            :options="specificFuneralNoticeOptions"
          />
        </s-field-validate>

        <s-button
          theme="outline"
          color="blue"
          class="button-border"
          icon="vmdi-plus-circle"
          @click.native="isSpecificFuneralNotice = !isSpecificFuneralNotice"
          v-if="(form.specific_funeral_notice === 'yes')"
          label="Add Personal wishes"
        />

        <div v-if="isSpecificFuneralNotice || form.type_of_flower || form.specific_funeral_notice === 'yes'" class="ml-4">
          <s-field-validate
            rules="max:64"
            label="Types of Flowers to have/avoid?">
            <s-textbox
              type="text"
              spellcheck="true"
              v-model="form.type_of_flower"
            />
          </s-field-validate>
          <s-field-validate
            class="mt-3"
            vid="Roses to place on the coffin"
            name="Roses to place on the coffin"
            label="Roses for the family to place on the coffin?"
          >
            <s-radio
              class="mt-2"
              v-model="form.allow_roses_for_family"
              :options="allowRosesForFamily"
            />
          </s-field-validate>
          <s-field-validate
            class="mt-3"
            vid="Donations to charity"
            name="Donations to charity"
            label="Donations to a charity instead of flowers?"
          >
            <s-radio
              class="mt-2"
              v-model="form.charity_instead_of_flowers"
              :options="charityInsteadOfFlowers"
            />
          </s-field-validate>
          <s-field-validate class="mt-3"
            rules="max:100"
            label="Please detail any other wishes for funeral flowers">
            <s-textbox
              type="text"
              spellcheck="true"
              v-model="form.floral_tributes_other_detail"
            />
          </s-field-validate>
        </div>

        <h4 class="text-primary">Services</h4>

        <s-field-validate
          vid="Service booklets"
          name="Service booklets"
          label="Do you wish to have funeral service booklets?"
        >
          <s-radio
            class="mt-2"
            v-model="form.funeral_service_booklets"
            :options="funeralServiceBooklets"
          />
        </s-field-validate>

        <s-field-validate
          vid="Personal Wishes streamed"
          name="Personal Wishes streamed"
          label="Do you wish the funeral to be recorded/live streamed?"
        >
          <s-radio
            class="mt-2"
            v-model="form.personal_wishes_streamed"
            :options="personalWishesStreamed"
          />
        </s-field-validate>

        <s-field-validate
          vid="Organise funeral cars"
          name="Organise funeral cars"
          label="Do you wish to organise funeral cars/limos?"
        >
          <s-radio
            class="mt-2"
            v-model="form.organize_car_for_family"
            :options="organizeCarForFamily"
          />
        </s-field-validate>

        <s-field-validate
          vid="Specific people informed funeral arrangments"
          name="Specific people informed funeral arrangments"
          label="Are there any specific people or organisations wish to be informed of your funeral arrangments?"
        >
          <s-radio
            class="mt-2"
            v-model="form.inform_arrangement_detail_to_specific_person_organization"
            :options="funeralArrangements"
          />
        </s-field-validate>

        <s-field-validate
          class="ml-3"
          rules="max:512"
          v-if="form.inform_arrangement_detail_to_specific_person_organization === 'yes'"
          vid="Contacts To Inform"
          name="Contacts To Inform"
          label="Contacts To Inform: (Former employer/ work colleagues. Distant friends/ organisations etc.)"
        >
          <s-textarea
            v-model="form.contacts_to_inform_arrangemants"
          />
        </s-field-validate>

        <s-field-validate
          vid="Instructions for an after funeral catering"
          name="Instructions for an after funeral catering"
          label="Do you wish to leave instructions for after funeral catering/reception?"
        >
          <s-radio
            class="mt-2"
            v-model="form.catering_reception_after_funeral"
            :options="cateringReceptionAfterFuneral"
          />
        </s-field-validate>

        <s-field-validate
          class="ml-3"
          rules="max:64"
          v-if="form.catering_reception_after_funeral === 'yes'"
          vid="Reception Venue"
          name="Reception Venue"
          label="Reception Venue/ Location"
        >
          <s-textbox
            v-model="form.reception_location"
          />
        </s-field-validate>

        <s-field-validate
          class="ml-3"
          rules="max:128"
          v-if="form.catering_reception_after_funeral === 'yes'"
          vid="Invited people"
          name="Invited people"
          label="People to invite"
        >
          <s-textbox
            v-model="form.reception_invites"
          />
        </s-field-validate>

        <s-field-validate
          class="ml-3"
          rules="max:64"
          v-if="form.catering_reception_after_funeral === 'yes'"
          vid="Type of refreshments"
          name="Type of refreshments"
          label="Type of catering/ refreshments (e.g. full meal or light refreshments)"
        >
          <s-textbox
            v-model="form.catering_type"
          />
        </s-field-validate>

        <s-field-validate
          class="ml-3"
          rules="max:512"
          v-if="form.catering_reception_after_funeral === 'yes'"
          vid="Reception other wishes"
          name="Reception other wishes"
          label="Please detail any other wishes"
        >
          <s-textbox
            v-model="form.catering_other_detail"
          />
        </s-field-validate>
      </template>

      <!-- <template #footer="{ saveItem, saving }" :class="['']">
        <div>
          <s-field-validate vid="Verified" name="Verified">
            <s-checkbox
              class="choice"
              id="check-data"
              label="Please tick here to confirm that you understand that disbursments/
third party fees are seperate to funeral director fees and are not within
the funeral directors direct control."
              v-model="isCheck"
            />
          </s-field-validate>
          <s-row class="mt-3">
            <s-column :size="12">
              <s-button
                fluid
                align="center"
                label="Submit"
                color="primary"
                :loader="saving"
                :disabled="!isCheck"
                @click.native="saveItem"
              />
            </s-column>
          </s-row>
        </div>
      </template> -->
    <!-- <template #footer="{saveItem,saving}" :class="['']">
      <s-button
        fluid
        align="right"
        label=" Next"
        color="primary"
        :loader="saving"
        icon="vmdi-arrow-right"
        @click.native="saveItem"
      />
    </template> -->
    <template #footer="{ saveItem, saving }" :class="['']">
      <s-row class="g-2">
        <s-column :size="4">
          <s-button
            fluid
            label="Back"
            theme="muted"
            @click.native="back"
            class="bg-grey-lighter text-grey"
          />
        </s-column>
        <s-column :size="4">
          <s-button
            fluid
            align="right"
            color="primary"
            :loader="saving"
            label="Next"
            icon="vmdi-arrow-right"
            @click.native="saveItem"
          />
        </s-column>
      </s-row>
    </template>
    </item-form>
    <cropper ref="cropper" @input="onCrop($event)" />
  </div>
</template>
<script>
import { addPersonalWishes } from "@/api/pre-planning/create";
export default {
  props: {
    companySlug: String,
    prePlanningId: [Number, String],
    perPlanningDetails: Object,
  },
  data() {
    return {
      formState: null,
      isFuneralNotice:false,
      isSpecificFuneralNotice:false,
      fields: [
        { name: "funeral_notice", value: "yes" },
        { name: "specific_funeral_notice", value: "yes" },
        //{ name: "notice_web", value: true },
        "notice_web",
        "notice_newspaper",
        "notice_radio",
        "funeral_notice",
        "notice_social_media",
        "funeral_service_booklets",
        "specific_funeral_notice",
        "image",
        "allow_roses_for_family",
        "online_notice_wishes",
        { name: "is_radio_station_notice", value: true },
        "radio_station_notice",
        "radio_notice_wishes",
        // { name: "is_radio_station_notice", value: true },
        //{ name: "notice_newspaper", value: true },
        // { name: "is_local_newspaper", value: true },
        // "local_newspaper",
        // { name: "is_national_newspaper", value: true },
        // "national_newspaper",
        // "newspaper_wishes",

        { name: "is_funeral_notice_instruction", value: true },
        "funeral_notice_instruction",

        { name: "is_donation_charity", value: true },
        "donation_charity",
        "donation_charity_wishes",

        { name: "floral_tributes", value: "not_specified" },
        "floral_tributes_wishes",

        { name: "funeral_stationery", value: "popular" },
        "funeral_stationery_wishes",

        { name: "specific_detail_for_ceremony", value: "yes" },
        "funeral_ceremony_request",
        { name: "after_funeral_service", value: "yes" },
        { name: "refereshment_for_guest", value: "yes" },
        "refereshment_for_guest_desc",
        { name: "amount_for_funeral_receptions", value: "1" },
        "amount_for_funeral_reception_wishes",
        { name: "people_notify", value: "yes" },
        "people_notify_text_one",
        "people_notify_text_two",
        { name: "after_burial", value: "wooden" },
        "after_burial_wishes",
        { name: "after_cremation", value: "basic" },
        "after_cremation_wishes",
        { name: "cremation_official_interment_arrange", value: "yes" },
        "cemetery_grave_location",
        { name: "cremate_scattered_particular_location", value: "yes" },
        "cremate_scattered_particular_specific_location",
        { name: "cremation_interred_columbarium", value: "yes" },
        "cremation_interred_columbarium_location",
        "remain_cremation_personal_wishes",
        "donation_charity",
        "funeral_notice_instruction",
        "type_of_flower",
        "floral_tributes_other_detail",
        "contacts_to_inform_arrangemants",
        "reception_location",
        "reception_invites",
        "catering_type",
        "catering_other_detail",
        "personal_wishes_streamed",
        "organize_car_for_family",
        "inform_arrangement_detail_to_specific_person_organization",
        "catering_reception_after_funeral",
        "is_donation_charity",
        "charity_instead_of_flowers",
      ],
      isCheck: false,
      image: "",
      photo: "with_photo",
      burialOptions: [
        {
          label:
            "Wooden temporary Grave Marker (Used until a more permanent memorial can be placed)",
          value: "wooden",
        },
        {
          label:
            "Monumental Masonry (Custom amount to be included as a contribution)",
          value: "monumental_masonry",
        },
      ],
      commonOptions: [
        {
          label: "Yes",
          value: "yes",
        },
        {
          label: "No",
          value: "no",
        },
      ],
      particularLocationOptions: [
        {
          id: "po-1",
          label: "Yes",
          value: "yes",
        },
        {
          id: "po-2",
          label: "No",
          value: "no",
        },
      ],
      columbariumOptions: [
        {
          id: "c-1",
          label: "Yes",
          value: "yes",
        },
        {
          id: "c-2",
          label: "No",
          value: "no",
        },
      ],
      cremationOptions: [
        {
          label:"Solid casket (Solid wood casket in choice finish- suitable for burying)",
          value: "solid_casket",
        },
        {
          label: "Basic (Container suitable for scattering)",
          value: "basic",
        },
        {
          label:"Eco scattering tube (Bio degradable decorative scattering tube suitable for scattering or burying)",
          value: "eco_scattering_tube",
        },
        {
          label:"Standard (Veneered wood cremated remains casket-suitable for burying) ",
          value: "standard",
        },
        {
          label:
            "Metal urn (Decorative cremated remains urn in a metallic finish)",
          value: "metal_urn",
        },
        {
          label: "Other",
          value: "other",
        },
      ],
      notifyOptions: [
        {
          label: "Yes",
          value: "yes",
        },
        {
          label: "No",
          value: "no",
        },
        {
          label: "My family or funeral arranger will/can look after that",
          value: "family_or_funeral_arranger",
        },
      ],
      amountOptions: [
        {
          label: "Yes",
          value: true,
        },
        {
          label: "No",
          value: false,
        },
      ],
      onlineOptions: [
        {
          label: "With photo",
          value: "with_photo",
        },
        {
          label: "Without photo",
          value: "without_photo",
        },
      ],
      stationeryOptions: [
        {
          label: "Standard (50 copies of a 4 page Order of Service)",
          value: "standard",
        },
        {
          label: "Popular (50-100 copies of a 4 page Order of Service)",
          value: "popular",
        },
        {
          label: "Custom amount to be included as a contribution",
          value: "customized",
        },
        {
          label: "None Required",
          value: "none",
        },
      ],
      ceremoneyOptions: [
        {
          label: "Yes",
          value: "yes",
        },
        {
          label: "No",
          value: "no",
        },
        {
          label: "My family or funeral arranger will/can look after that",
          value: "family_or_funeral_arranger",
        },
        {
          label: "No ceremony required",
          value: "not_required",
        },
      ],
      refreshmentOptions: [
        {
          label: "Yes",
          value: "yes",
        },
        {
          label: "No",
          value: "no",
        },
        {
          label: "My family can decide",
          value: "family",
        },
        {
          label: "Other",
          value: "other",
        },
      ],
      serviceOptions: [
        {
          label: "Yes",
          value: "yes",
        },
        {
          label: "No",
          value: "no",
        },
        {
          label: "No ceremony required",
          value: "not_required",
        },
      ],
      funeralNoticeOptions: [
        {
          label: "Yes",
          value: "yes",
        },
        {
          label: "No",
          value: "no",
        },
        {
          label: "Not Sure",
          value: "not_sure",
        },
        {
          label: "Let my family decide",
          value: "family_or_funeral_arranger",
        },
      ],
      specificFuneralNoticeOptions: [
        {
          label: "Yes",
          value: "yes",
        },
        {
          label: "No",
          value: "no",
        },
        {
          label: "Not Sure",
          value: "not_sure",
        },
        {
          label: "Let my family decide",
          value: "family_or_representative_will_decide",
        },
      ],
      funeralServiceBooklets:[
        {
          label: "Yes",
          value: "yes",
        },
        {
          label: "No",
          value: "no",
        },
        {
          label: "Not Sure",
          value: "not_sure",
        },
        {
          label: "Let my family decide",
          value: "family_or_representative_will_decide",
        },
      ],
      personalWishesStreamed:[
        {
          label: "Yes",
          value: "yes",
        },
        {
          label: "No",
          value: "no",
        },
        {
          label: "Not Sure",
          value: "not_sure",
        },
        {
          label: "Let my family decide",
          value: "family_or_representative_will_decide",
        },
      ],
      organizeCarForFamily:[
        {
          label: "Yes",
          value: "yes",
        },
        {
          label: "No",
          value: "no",
        },
        {
          label: "Not Sure",
          value: "not_sure",
        },
        {
          label: "Let my family decide",
          value: "family_or_representative_will_decide",
        },
      ],
      funeralArrangements:[
        {
          label: "Yes",
          value: "yes",
        },
        {
          label: "No",
          value: "no",
        },
        {
          label: "Not Sure",
          value: "not_sure",
        },
        {
          label: "Let my family decide",
          value: "family_or_representative_will_decide",
        },
      ],
      cateringReceptionAfterFuneral:[
        {
          label: "Yes",
          value: "yes",
        },
        {
          label: "No",
          value: "no",
        },
        {
          label: "Not Sure",
          value: "not_sure",
        },
        {
          label: "Let my family decide",
          value: "family_or_representative_will_decide",
        },
      ],
      funeralNoticePublished:[
        {
          label: "Online",
          value: "online",
        },
        {
          label: "Newspaper",
          value: "newspaper",
        },
        {
          label: "Radio",
          value: "radio",
        },
        {
          label: "Social media",
          value: "social_media",
        },
      ],
      isDonationCharity:[
        {
          label: "Yes",
          value: true,
        },
        {
          label: "No",
          value: false,
        },
      ],
      allowRosesForFamily:[
        {
          label: "Yes",
          value: "yes",
        },
        {
          label: "No",
          value: "no",
        },
      ],
      charityInsteadOfFlowers:[
        {
          label: "Yes",
          value: "yes",
        },
        {
          label: "No",
          value: "no",
        },
      ],
      
    };
  },
  computed: {
    typeNotSure() {
      if (this.perPlanningDetails?.funeral_type === "not_sure") {
        return true;
      }
      return false;
    },
    typeCremated() {
      if (this.perPlanningDetails?.funeral_type === "cremation") {
        return true;
      }
      return false;
    },
    typeBuried() {
      if (this.perPlanningDetails?.funeral_type === "burial") {
        return true;
      }
      return false;
    },
  },
  components: {
    ItemForm: require("@/components/ItemForm").default,
    Cropper: require("@/components/Cropper").default,
    //PersonalWishes: require("@/components/PersonalWishes").default,
  },

//  watch:{
//     'formState.notice_web'(newVal){
//       if (newVal !== null) {
//         this.formState.funeral_notice ="yes";  
//       } 
//       else{
//         this.formState.funeral_notice ="no";  
//       }
//     },
//     'formState.funeral_notice'(newVal){
//       if (newVal == 'no'){
//         this.formState.notice_web = null; 
//         this.formState.funeral_notice ="no";
//       } 
//     },
    
//   },
  methods: {
    get(id) {
      return {
        ...this.perPlanningDetails,
        notice_newspaper:this.perPlanningDetails.notice_newspaper == "yes" ? true : false,
        notice_web:this.perPlanningDetails.notice_web == "yes" ? true : false,
        notice_radio:this.perPlanningDetails.notice_radio == "yes" ? true : false,
        notice_social_media:this.perPlanningDetails.notice_social_media == "yes" ? true : false,
      };
    },
    save(id, data) {
      data["prePlanningId"] = this.prePlanningId;
      data["image"] = this.image;
      data["notice_newspaper"] = this.formState.notice_newspaper == true ? 'yes' : 'no';
      data["notice_radio"] = this.formState.notice_radio == true ? 'yes' : 'no';
      data["notice_web"] = this.formState.notice_web == true ? 'yes' : 'no';
      data["notice_social_media"] = this.formState.notice_social_media == true ? 'yes' : 'no';


      return addPersonalWishes(data).then((res) => {
        this.$emit("refresh");
        this.$router.push({ name: "confirmation" });
      });

      // return addPersonalWishes(data).then((res) => {
      //   if (this.companySlug) {
      //     this.$router.push({
      //       name: "pre-planning-thank-you",
      //     });
      //   } else {
      //     this.$router.push({ name: "pre-planning-index" });
      //   }
      //   this.$emit("refresh");
      // });

    },
    back() {
      this.$router.push({
        name: "view-visitation-details",
      });
    },
    onCrop({ id, value }) {
      this[id] = value;
    },
    openCropper(id, img, ratio) {
      if (!img) return;
      this.$refs.cropper.open(id, img, {
        force: true,
        aspectRatio: ratio,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.button-border {
  border: 1px dashed var(--color--blue) !important;
}
.notice-checkbox{
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 480px){
  .notice-checkbox {
    .choice__label {
      margin-bottom: 10px!important;
    }
  }
}
</style>
