var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.colorExsist)?_c('company-profile',{attrs:{"business":_vm.business}}):_vm._e(),_c('div',{staticClass:"p-4"},[_vm._m(0),_c('div',{staticClass:"copy-link flex flex--start flex--fit flex--between mb-5"},[_c('div',[_c('div',{staticClass:"text-grey-light mb-2"},[_vm._v(" Click to Copy Link : ")]),_c('div',{staticClass:"link-text text-lg text-primary-darkest text-bold"},[_c('p',[_vm._v(_vm._s(_vm.prePlanningSlug))]),_c('s-button',{staticClass:"bg-primary-lightest",attrs:{"shape":"circle","target":"_blank","color":"text-primary","href":_vm.prePlanningSlug,"icon":"vmdi-open-in-new","title":"Open Link in New Tab"}})],1)]),_c('s-button',{staticClass:"bg-primary-lightest copy-button",attrs:{"icon":"vmdi-content-copy","color":"text-primary","shape":"circle","title":"Copy Link"},nativeOn:{"click":function($event){return _vm.prePlanningCopySlug($event)}}})],1),_c('sp-list',{ref:"list",staticClass:"h-100",attrs:{"endpoint":"/v1/admin/pre-planning","params":_vm.params,"attrs":_vm.columns,"actions":_vm.actions,"per-page":10}},[_c('sp-list-table',{scopedSlots:_vm._u([{key:"mobile_no",fn:function(ref){
var item = ref.item;
return [(item.mobile_no)?_c('div',[_vm._v(" "+_vm._s(item.mobile_no)+" ")]):_c('div',{staticClass:"text-xs text-italic text-grey"},[_vm._v(" (No Details Provided) ")])]}},{key:"responsible_name",fn:function(ref){
var item = ref.item;
return [(item.responsible_name)?_c('div',[_vm._v(" "+_vm._s(item.responsible_name)+" ")]):_c('div',{staticClass:"text-xs text-italic text-grey"},[_vm._v(" (No Details Provided) ")])]}},{key:"responsible_email",fn:function(ref){
var item = ref.item;
return [(item.responsible_email)?_c('div',[_vm._v(" "+_vm._s(item.responsible_email)+" ")]):_c('div',{staticClass:"text-xs text-italic text-grey"},[_vm._v(" (No Details Provided) ")])]}},{key:"beneficiary_name",fn:function(ref){
var item = ref.item;
return [(item.beneficiary_name)?_c('div',[_vm._v(" "+_vm._s(item.beneficiary_name)+" ")]):_c('div',{staticClass:"text-xs text-italic text-grey"},[_vm._v(" (No Details Provided) ")])]}},{key:"ceremony_location",fn:function(ref){
var item = ref.item;
return [(item.ceremony_location)?_c('div',[_vm._v(" "+_vm._s(item.ceremony_location)+" ")]):_c('div',{staticClass:"text-xs text-italic text-grey"},[_vm._v(" (No Details Provided) ")])]}}])})],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-3 bb-lightest mb-3"},[_c('p',{staticClass:"text-xl text-primary-light request-title"},[_vm._v(" Funeral Request Link ")])])}]

export { render, staticRenderFns }