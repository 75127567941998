<template>
  <item-form
    :gap="3"
    :get="get"
    ref="item"
    class="p-4 field-group-plan box"
    :item="true"
    :save="save"
    width="400px"
    :footer="true"
    :notify="true"
    :fields="fields"
    :redirect="false"
    :breadcrumb="false"
    :form-state.sync="formState"
  >
    <template #default="{form}">
      <div class="text-primary text-bold text-md fields-plan-full">
        Contact Details
      </div>
      <s-field-validate class="fields-plan-full" label="Is this plan for you?" rules="required">
        <s-radio :options="planForOptions" v-model="form.plan_for_you" />
      </s-field-validate>
      <s-field-validate class="fields-plan" label="Your Full Name" rules="required|max:64">
        <s-textbox v-model="form.name" type="text" spellcheck="true" />
      </s-field-validate>
      <s-field-validate class="fields-plan" label="Address" rules="required|max:128">
        <s-textarea v-model="form.address" type="text" spellcheck="true" />
      </s-field-validate>

      <s-field-validate class="fields-plan" label="Eircode" rules="required|max:32">
        <s-textbox v-model="form.eir_code" type="text" />
      </s-field-validate>

      <s-field-validate class="fields-plan" label="Contact Number" rules="required|min:7|max:15">
        <s-textbox v-model="form.mobile_no" type="number" />
      </s-field-validate>
      <s-field-validate class="fields-plan" label="Email" rules="required|email|max:64">
        <s-textbox v-model="form.email" type="text" name="email"/>
      </s-field-validate>

      <s-field-validate class="fields-plan v-calendar-custom field-date" v-if="planYes" label="Date of Birth" rules="required">
        <v-date-picker v-model="form.dob" :clickable="true" />
      </s-field-validate>

      <!-- <s-field-validate class="fields-plan" v-if="planYes" label="Date of Birth" rules="date">
        <s-textbox v-model="form.dob" type="date" />
      </s-field-validate> -->

     <s-field-validate class="fields-plan" v-if="planYes" label="Religion (If any)" rules="max:64">
        <s-textarea v-model="form.religion" type="text" spellcheck="true" />
      </s-field-validate>

      <div class="text-xs fields-plan-full" v-if="planYes">
        I would like the following person to be responsible for making my
        funeral arrangements:
      </div>

      <div class="text-primary text-bold text-md fields-plan-full" v-if="planYes">
        Your Representative's Details
      </div>

      <s-field-validate class="fields-plan" v-if="planYes" label="Full Name" rules="required|max:64">
        <s-textbox
          type="text"
          spellcheck="true"
          v-model="form.responsible_name"
        />
      </s-field-validate>
      <s-field-validate class="fields-plan" v-if="planYes" label="Relationship to You" rules="required|max:64">
        <s-textbox v-model="form.responsible_relation_to_you" type="text" />
      </s-field-validate>

      <s-field-validate class="fields-plan" v-if="planYes" label="Representative's Address" rules="required|max:512">
        <s-textarea v-model="form.responsible_address" type="text" spellcheck="true" />
      </s-field-validate>

      <s-field-validate class="fields-plan" v-if="planYes" label="Representative's Eircode" rules="max:20">
        <s-textbox v-model="form.representative_eir_code" type="text" />
      </s-field-validate>

      <s-field-validate class="fields-plan" v-if="planYes" label="Representative's Contact Number" rules="required|min:7|max:15">
        <s-textbox v-model="form.representative_phone_number" type="number" />
      </s-field-validate>
      <s-field-validate class="fields-plan" v-if="planYes" label="Representative's Email" rules="max:64">
        <s-textbox v-model="form.responsible_email" type="text" name="responsible_email" />
      </s-field-validate>


      <!-- If plan value: NO -->
      <div class="text-primary text-bold text-md fields-plan-full" v-if="planNo">
        Beneficiary Details
        <p><small>(the person whose funeral wishes are to be carried out)</small></p>
      </div>

      <s-field-validate class="fields-plan" v-if="planNo" label="Beneficiary’s Full Name" rules="max:64">
        <s-textbox
          type="text"
          spellcheck="true"
          v-model="form.beneficiary_name"
        />
      </s-field-validate>
      <s-field-validate class="fields-plan" v-if="planNo" label="Relationship to You" rules="max:64">
        <s-textbox v-model="form.beneficiary_relation_to_you" type="text" />
      </s-field-validate>
      <s-field-validate class="fields-plan" v-if="planNo" label="Beneficiary's Address" rules="max:512">
        <s-textarea v-model="form.beneficiary_address" type="text" spellcheck="true" />
      </s-field-validate>

      <s-field-validate class="fields-plan" v-if="planNo" label="Eircode" rules="max:32">
        <s-textbox v-model="form.beneficiary_eir_code" type="text" />
      </s-field-validate>

      <s-field-validate class="fields-plan" v-if="planNo" label="Contact Number" rules="min:7|max:15">
        <s-textbox v-model="form.beneficiary_phone_number" type="number" />
      </s-field-validate>
      <s-field-validate class="fields-plan" v-if="planNo" label="Beneficiary's Email" rules="max:64">
        <s-textbox v-model="form.beneficiary_email" type="text" name="beneficiary_email"/>
      </s-field-validate>
      <s-field-validate class="fields-plan" v-if="planNo" label="Religion (If any)" rules="max:64">
        <s-textarea v-model="form.beneficiary_religion" type="text" spellcheck="true" />
      </s-field-validate>
    

    </template>
    <template #footer="{saveItem,saving}" :class="['']">
      <div>
        <s-row class="mt-3">
          <s-column :size="4">
            <s-button
              fluid
              align="right"
              label=" Next"
              color="primary"
              :loader="saving"
              icon="vmdi-arrow-right"
              @click.native="saveItem"
            />
          </s-column>
        </s-row>
      </div>
    </template>
  </item-form>
</template>
<script>
import { addIntroduction } from "@/api/pre-planning/create";
export default {
  props: {
    prePlanningId: [Number, String],
    perPlanningDetails: Object,
  },
  components: {
    ItemForm: require("@/components/ItemForm").default,
    VDatePicker: require("@/components/VDatePicker").default,
  },
  data() {
    return {
      formState: null,
      fields: [
        { name: "plan_for_you", value: "yes" },
        "name",
        "address",
        "eir_code",
        "email",
        "mobile_no",
        "dob",
        "religion",
        "responsible_name",
        "responsible_relation_to_you",
        "responsible_address",
        "representative_eir_code",
        "representative_phone_number",
        "responsible_email",
        "beneficiary_name",
        "beneficiary_relation_to_you",
        "beneficiary_address",
        "beneficiary_eir_code",
        "beneficiary_phone_number",
        "beneficiary_email",
        "beneficiary_religion",
       /* "arrangement_relation",*/
      ],
      planForOptions: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ],
    };
  },
  computed:{
    planNo() {
      if (this.formState?.plan_for_you === "no") {
        return true;
      }
      return false;
    },
    planYes() {
      if (this.formState?.plan_for_you === "yes") {
        return true;
      }
      return false;
    },
  },
  
  methods: {
    get() {
      return this.perPlanningDetails;
    },
    async save(id, data) {
      data["prePlanningId"] = this.prePlanningId;
      return addIntroduction(data).then((res) => {
        this.$router.push({ name: "per-planning-cremation" });
        this.$emit("refresh");
      });
    },
  },
};
</script>
