<template>
  <div class="h-100">
    <s-layout
      class="direct-box"
      full-height
      right-size="400px"
      :right-classlist="['p-3', 'bl-lightest']"
    >
      <template #default>
        <item-form
          :item="slug"
          :fields="fields"
          :save="save"
          :get="get"
          class="p-4"
          :breadcrumb="false"
          :redirect="false"
          width="710px"
          ref="item"
          :notify="true"
          :gap="3"
          :form-state.sync="formState"
        >
          <template #default="{form}">
            <div class="text-primary text-bold text-md">{{ form.name }}</div>
            <s-field-validate name="Content" rules="required">
              <editor v-model="form.content" v-if="slug !== 'email-content'" />
              <s-textarea
                rows="30"
                v-model="form.content"
                v-if="slug === 'email-content'"
                spellcheck="true"
              />
            </s-field-validate>
            <s-field label="Image" v-if="!isVisible">
              <div>
                <s-media
                  class="radius-3"
                  :readonly="false"
                  ratio="1x1"
                  width="120px"
                  :value="form.image ? form.image : templateImage"
                  @input="openCropper('templateImage', $event, 1)"
                  accept=".jpg,.jpeg,.png"
                  value-type="base64"
                  :max-file-size="5 * 1024 * 1024"
                  :changeable="false"
                  @remove="removeProfile()"
                />
                <!-- @remove="removeProfile()" -->
              </div>
            </s-field>
          </template>
        </item-form>
      </template>
      <template #right v-if="isSideContent">
        <div class="text-grey mb-3 text-md">
          Form Fields
        </div>
        <div v-if="tagsData">
          <card
            :title="tag.category"
            v-for="(tag, index) in tagsData"
            :key="index"
            :values="tag.fields"
          />
        </div>
      </template>
    </s-layout>
    <cropper ref="cropper" @input="onCrop($event)" />
  </div>
</template>
<script>
import { get, update, getTags } from "@/api/templateContent";
import { mapGetters } from "vuex";
import user from "@/api/user";

export default {
  props: {
    slug: String,
  },
  components: {
    ItemForm: require("@/components/ItemForm").default,
    Editor: require("@/components/Editor").default,
    Card: require("@/views/notice/Card").default,
    Cropper: require("@/components/Cropper").default,
  },
  computed: {
    ...mapGetters(["business"]),
    isVisible() {
      if (
        this.slug === "direct-introduction" ||
        this.slug === "simple-introduction" ||
        this.slug === "tailored-introduction"
      ) {
        return false;
      }
      return true;
    },
    isSideContent() {
      let slugsNot = [
        "direct-introduction",
        "simple-introduction",
        "tailored-introduction",
        "notice-disclaimer",
      ];
      if (slugsNot.includes(this.slug)) {
        return false;
      }
      return true;
    },
  },
  data() {
    return {
      fields: ["name", "content", "image"],
      tagsData: null,
      templateImage: null,
      formState: null,
      mediaId: null,
    };
  },
  created() {
    getTags({ slug: this.slug }).then((res) => {
      this.tagsData = res.tags;
    });
  },
  methods: {
    get() {
      return get({ slug: this.slug, id: this.business.id }).then((res) => {
        this.mediaId = res?.media_id;
        return { ...res };
      });
    },
    async save(id, data) {
      data.business_id = this.business.id;
      data.slug = this.slug;
      data.image = this.templateImage ? this.templateImage : null;
      return update(data).then((res) => {
        this.$router.push({ name: "template-content-list" });
      });
    },
    onCrop({ id, value }) {
      this[id] = value;
    },
    openCropper(id, img, ratio) {
      if (!img) return;
      this.$refs.cropper.open(id, img, {
        force: true,
        aspectRatio: ratio || 1,
      });
    },
    removeProfile() {
      let data = this.mediaId;
      this.formState.image = null;
      this.templateImage = null;
      return user
        .mediaRemove(data)
        .then((res) => {
          this.$notify({
            title: res.message,
            type: "success",
            text: this.error,
          });
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
  },
};
</script>
