<template>
  <s-layout
    ref="companyDetails"
    class="company-box"
    full-height
    :left-classlist="['br-lightest']"
    left-size="200px"
  >
    <template #left>
      <sub-nav class="bt-lightest p-2">
        <s-nav-item
          class="is-root"
          :to="{ name: 'company-profile' }"
          icon="vmdi-account-supervisor-circle"
          label="Profile"
        />
        <s-nav-item
          :to="{ name: 'company-logo' }"
          icon="vmdi-image"
          label="Logo"
        />
        <s-nav-item
          :to="{ name: 'template-content-list' }"
          icon="vmdi-file-document-multiple"
          label="Templates"
        />
        <!-- <s-nav-item
          :to="{ name: 'service-comparison' }"
          icon="vmdi-arrange-bring-to-front"
          label="Service Comparisons"
        /> -->
        <!-- <s-nav-item
          :to="{ name: 'company-welcome-screen' }"
          icon="vmdi-file-document-edit"
          label="Welcome Screen"
        /> -->
      </sub-nav>
    </template>
    <template #default>
      <router-view />
    </template>
  </s-layout>
</template>
<script>
export default {
  components: {
    SubNav: require("@/components/SubNav").default,
  },
};
</script>
<style lang="scss">
@media (max-width: 780px) {
  .company-box {
    display: flex;
    flex-direction: column;
    .layout__left {
      .nav {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        overflow: auto;
        padding: 10px !important;
      }
      .sub-nav .nav__item + .nav__item {
        margin: 0px;
      }
    }
    .item-form {
      width: 100% !important;
    }
    .layout__content {
      flex: 1;
      overflow: auto;
    }
  }
}
</style>
