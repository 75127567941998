<template>
  <div class="h-100">
    <portal to="header" v-if="!isNotDashboard">
      <s-button
        class=""
        size="sm"
        color="primary"
        theme="primary"
        label="Add New"
        icon="vmdi-plus"
        :to="{ name: 'pre-planning-welcome' }"
      />
    </portal>
    <sp-list
      endpoint="/v1/funeral-pre-planning"
      :actions="!isNotDashboard ? ['refresh', 'settings'] : ['']"
      :footer="!isNotDashboard"
      :attrs="columns"
      :params="params"
      :per-page="10"
      :class="!isNotDashboard ? 'h-100' : ''"
      ref="list"
    >
      <sp-list-table @rowClick="editPage($event.uuid)">
        <!-- <template #status="{item}">
          <s-button
            :class="background[item.status]"
            class="text-xs text-normal h-4 py-3"
            shape="pill"
            :label="item.status"
          />
        </template> -->
        <template #mobile_no="{item}">
          <div v-if="item.mobile_no">
            {{ item.mobile_no }}
          </div>
          <div v-else class="text-xs text-italic text-grey">
            (No Details Provided)
          </div>
        </template>
        <template #responsible_name="{item}">
          <div v-if="item.responsible_name">
            {{ item.responsible_name }}
          </div>
          <div v-else class="text-xs text-italic text-grey">
            (No Details Provided)
          </div>
        </template>
        <template #responsible_email="{item}">
          <div v-if="item.responsible_email">
            {{ item.responsible_email }}
          </div>
          <div v-else class="text-xs text-italic text-grey">
            (No Details Provided)
          </div>
        </template>
        <template #beneficiary_name="{item}">
          <div v-if="item.beneficiary_name">
            {{ item.beneficiary_name }}
          </div>
          <div v-else class="text-xs text-italic text-grey">
            (No Details Provided)
          </div>
        </template>
        <template #ceremony_location="{item}">
          <div v-if="item.ceremony_location">
            {{ item.ceremony_location }}
          </div>
          <div v-else class="text-xs text-italic text-grey">
            (No Details Provided)
          </div>
        </template>
      </sp-list-table>
    </sp-list>
  </div>
</template>
<script>
export default {
  props: {
    business: Object,
    isNotDashboard: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      background: {
        pending: "bg-warning-lightest text-warning",
        completed: "bg-success-lightest text-success",
      },
    };
  },
  computed: {
    params() {
      return {
        business_id: this.business?.id,
      };
    },
    columns() {
      return [
        { name: "_index", label: "#", fix: true },
        {
          name: "name",
          fix: true,
        },
        {
          name: "email",
          fix: true,
        },
        {
          name: "funeral_type",
          value: (item) => item.funeral_type_text,
          fix: true,
        },
        {
          name: "mobile_no",
          fix: true,
        },
        {
          name: "responsible_name",
          fix: true,
        },
        {
          name: "responsible_email",
          fix: true,
        },
        {
          name: "beneficiary_name",
          fix: true,
        },
        {
          name: "ceremony_location",
          fix: true,
        },
        // {
        //   name: "status",
        //   fix: true,
        // },
      ];
    },
  },
  methods: {
    editPage(uuid) {
      this.$router.push({
        params: {
          prePlanningId: uuid,
        },
        name: "pre-planning-starting",
      });
    },
  },
};
</script>
