<template>
  <div class="h-100">
    <request class="h-100" #default="{res}" :get="get" ref="prePlanningDetails">
      <s-layout full-height class="sub-box layout--plan">
        <template #left>
          <sub-nav class="bt-lightest p-2 plan-nav">
            <s-nav-item
              class="is-root"
              icon="vmdi-numeric-1-box"
              label="Contact Details"
              :to="{ name: 'pre-planning-introduction' }"
            />
            <s-nav-item
              icon="vmdi-numeric-2-box"
              label="My Burial/ Cremation Details"
              :to="{ name: 'per-planning-cremation' }"
              :disabled="routesEnable"
            />
            <s-nav-item
              icon="vmdi-numeric-3-box"
              label="My Ceremony Wishes"
              :to="{ name: 'my-ceremony-wishes' }"
              :disabled="routesEnable"
            />
            <s-nav-item
              icon="vmdi-numeric-4-box"
              label="My Viewing & Visitation (Wake) Wishes"
              :to="{ name: 'view-visitation-details' }"
              :disabled="routesEnable"
            />
            <s-nav-item
              icon="vmdi-numeric-5-box"
              label="My Personal Wishes"
              :to="{ name: 'my-personal-wishes' }"
              :disabled="routesEnable"
            />
            <s-nav-item
              icon="vmdi-numeric-6-box"
              label="Confirmation"
              :to="{ name: 'confirmation' }"
              :disabled="routesEnable"
            />
          </sub-nav>
        </template>
        <template #default>
          <router-view
            :perPlanningDetails="res"
            @refresh="$refs.prePlanningDetails.refresh()"
          />
        </template>
      </s-layout>
    </request>
  </div>
</template>
<script>
import { getDetails } from "@/api/pre-planning/create";
export default {
  props: {
    prePlanningId: [Number, String],
  },
  data() {
    return {
      perPlanningData: null,
    };
  },
  computed: {
    routesEnable() {
      if (this.perPlanningData?.name && this.perPlanningData?.email) {
        return false;
      }
      return true;
    },
    specialRequstEnable() {
      if (
        !this.routesEnable &&
        this.perPlanningData?.transportaion_facility &&
        this.perPlanningData?.funeral_notice
      ) {
        return false;
      }
      return true;
    },
  },
  components: {
    SubNav: require("@/components/SubNav").default,
    Request: require("@/components/Request").default,
  },
  methods: {
    get() {
      if (this.$route.name == "pre-planning-thank-you") {
        return {};
      }
      return getDetails({ prePlanningId: this.prePlanningId }).then((res) => {
        this.perPlanningData = res;
        return res;
      });
    },
  },
};
</script>
<style lang="scss">

.layout--plan{
  position: relative;
  display: flex;
  flex-direction: column;
  .layout__left {
    flex: 0 0 60px;
    .nav {
      display: flex;
      flex-direction: row;
      padding: 5px !important;
      background-color: #{--color("primary")};
      max-width: 100%;
      overflow: inherit;
      > span {
        display: flex;
      }
      > li {
        & + li {
          margin-top: 0px;
        }
      }
      &__link{
        color: #fff;
        &:hover{
          background-color: #fff;
          color:#{--color("primary")};
        }
      }
      &::-webkit-scrollbar{
        width: 1em;
      }
      &::-webkit-scrollbar-track{
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }
      &::-webkit-scrollbar-thumb{
        background-color: darkgrey;
        outline: 1px solid slategrey;
      }
    }
  }
  .layout__content {
    flex: 1;
    overflow: auto;
    .item-form {
      width: 100% !important;
    }
  }
}
.sub-nav .nav__item--exact-active .button{
  background-color: #fff;
  color: #{--color("primary")};
}
.sub-nav .nav__item--exact-active .button:hover{
  background-color: #fff;
  color: #{--color("primary")};
}
.field-group-plan .field-group.g-3 {
  display: flex;
  flex-wrap: wrap;
}
.fields-plan {
  flex: 0 0 32%;
}
.fields-plan-full{
  flex: 0 0 100%;
}
textarea {
  max-height: 40px !important;
  line-height: 22px;
}
.declaration label.choice__label {
  flex-direction: row-reverse;
  &::before{
    margin-left: 100px;
  }
}
.confirmation .choices {
  display: block;
  .choice{
    margin-bottom: 20px;
  }
}
.confirmation__wrap .choice {
    flex: 0 0 200px;
}
.member--check .field__content {
  flex-direction: row-reverse;
  align-items: flex-start;
}
.member--check label {
    flex: 1 1 auto;
    line-height: 22px;
}
.member--check label strong{
  position: relative;
  margin: 0 5px;
  border-bottom: 1px solid #000;
  display: inline-block;
  text-align: center;
  padding: 0 20px;
}
.member--check label strong.is-dash-active {
    display: inline-block;
    text-decoration: none;
    border-bottom: 1px solid #000;
    min-width: 150px;
    text-align: center;
    padding: 0 15px;
}
.member--check .checkbox.choice {
    flex: 0 0 20px !important;
    width: 20px !important;
    margin: 0 !important;
    display: block;
}

strong.member-name {
    min-width: 150px;
    display: inline-block;
    text-align: center;
    border-bottom: 1px solid;
    padding: 0 15px;
}
@media screen and (max-width: 991px){
  .fields-plan {
    flex: 0 0 48%;
  }
}
@media screen and (max-width: 780px){
  .sub-box {
    position: relative;
    display: flex;
    flex-direction: column;
    .layout__left {
      flex: 0 0 60px;
      .nav {
        display: flex;
        flex-direction: row;
        padding: 5px !important;
        overflow: auto;
        > span {
          display: flex;
        }
        > li {
          & + li {
            margin-top: 0px;
          }
        }
      }
    }
    .layout__content {
      flex: 1;
      overflow: auto;
      .item-form {
        width: 100% !important;
      }
    }
  }
}
@media screen and (max-width: 480px){
  .fields-plan {
    flex: 0 0 100%;
  }
}
</style>
