<template>
  <div>
    <company-profile v-if="colorExsist" :business="business" />
    <div class="p-4">
      <div class="pb-3 bb-lightest mb-3">
        <p class="text-xl text-primary-light request-title">
          Funeral Request Link
        </p>
      </div>
      <div class="copy-link flex flex--start flex--fit flex--between mb-5">
        <div>
          <div class="text-grey-light mb-2">
            Click to Copy Link :
          </div>
          <!-- <div class="link-text text-lg text-primary-darkest text-bold">
            <p>{{ businessSlug }}</p>
            <s-button
              :href="businessSlug"
              target="_blank"
              icon="vmdi-open-in-new"
              color="text-primary"
              class="bg-primary-lightest"
              shape="circle"
              title="Open Link in New Tab"
            />
          </div> -->
          <div class="link-text text-lg text-primary-darkest text-bold">
            <p>{{ prePlanningSlug }}</p>
            <s-button
              shape="circle"
              target="_blank"
              color="text-primary"
              :href="prePlanningSlug"
              icon="vmdi-open-in-new"
              class="bg-primary-lightest"
              title="Open Link in New Tab"
            />
          </div>
        </div>
        <s-button
          icon="vmdi-content-copy"
          color="text-primary"
          class="bg-primary-lightest copy-button"
          shape="circle"
          title="Copy Link"
          @click.native="prePlanningCopySlug"
        />
      </div>
      <sp-list
        class="h-100"
        endpoint="/v1/admin/pre-planning"
        :params="params"
        :attrs="columns"
        :actions="actions"
        :per-page="10"
        ref="list"
      >
        <sp-list-table>
          <template #mobile_no="{item}">
            <div v-if="item.mobile_no">
              {{ item.mobile_no }}
            </div>
            <div v-else class="text-xs text-italic text-grey">
              (No Details Provided)
            </div>
          </template>
          <template #responsible_name="{item}">
            <div v-if="item.responsible_name">
              {{ item.responsible_name }}
            </div>
            <div v-else class="text-xs text-italic text-grey">
              (No Details Provided)
            </div>
          </template>
          <template #responsible_email="{item}">
            <div v-if="item.responsible_email">
              {{ item.responsible_email }}
            </div>
            <div v-else class="text-xs text-italic text-grey">
              (No Details Provided)
            </div>
          </template>
          <template #beneficiary_name="{item}">
            <div v-if="item.beneficiary_name">
              {{ item.beneficiary_name }}
            </div>
            <div v-else class="text-xs text-italic text-grey">
              (No Details Provided)
            </div>
          </template>
          <template #ceremony_location="{item}">
            <div v-if="item.ceremony_location">
              {{ item.ceremony_location }}
            </div>
            <div v-else class="text-xs text-italic text-grey">
              (No Details Provided)
            </div>
          </template>
        </sp-list-table>
      </sp-list>
    </div>
  </div>
</template>
<script>
import { format } from "date-fns";

import funeraldirector from "@/api/funeraldirector";
export default {
  props: {
    id: [String, Number],
  },
  data() {
    return {
      background: {
        Paid: "bg-success-lightest text-success",
        Completed: "bg-success-lightest text-success",
        Unpaid: "bg-warning-lightest text-warning",
        Open: "bg-warning-lightest text-warning",
        Refund: "bg-danger-lightest text-danger",
        Cancelled: "bg-danger-lightest text-danger",
        Pending: "bg-warning-lightest text-warning",
      },
      business: null,
      payment_status: [
        {
          name: "Unpaid",
          value: 0,
          class: "bg-warning-lightest point-box--warning text-warning",
        },
        {
          name: "Paid",
          value: 0,
          class: "bg-success-lightest point-box--success text-success",
        },
        {
          name: "Refund",
          value: 0,
          class: "bg-danger-lightest point-box--danger text-danger",
        },
      ],
      funeral_status: [
        {
          name: "Open",
          value: 0,
          class: "bg-warning-lightest point-box--warning text-warning",
        },
        {
          name: "Completed",
          value: 0,
          class: "bg-success-lightest point-box--success text-success",
        },
        {
          name: "Cancelled",
          value: 0,
          class: "bg-danger-lightest point-box--danger text-danger",
        },
      ],
    };
  },
  computed: {
    colorExsist() {
      return this.business?.brand_color || this.business?.text_color;
    },
    businessSlug() {
      return (
        window.location.origin + "/app/#/" + (this.business?.url_slug || "")
      );
    },
    prePlanningSlug() {
      return (
        window.location.origin +
        "/app/#/" +
        (this.business?.url_slug || "") +
        "/pre-planning/welcome"
      );
    },
    actions() {
      return ["refresh"];
    },
    columns() {
      return [
        { name: "_index", label: "#", fix: true },
        {
          name: "name",
          fix: true,
        },
        {
          name: "email",
          fix: true,
        },
        {
          name: "funeral_type",
          value: (item) => item.funeral_type_text,
          fix: true,
        },
        {
          name: "mobile_no",
          fix: true,
        },
        {
          name: "responsible_name",
          fix: true,
        },
        {
          name: "responsible_email",
          fix: true,
        },
        {
          name: "beneficiary_name",
          fix: true,
        },
        {
          name: "ceremony_location",
          fix: true,
        },
      ];
    },
    params() {
      return {
        user_id: this.id,
        business_id: this.business?.id,
      };
    },
  },
  components: {
    CompanyProfile: require("@/components/CompanyProfile").default,
  },
  mounted() {
    this.getFuneral();
  },
  methods: {
    getFuneral() {
      return funeraldirector.getFuneralDirector(this.id).then((res) => {
        this.business = res.businesses[0];
        this.payment_status[0].value = res.businesses[0].unpaid_funeral_count;
        this.payment_status[1].value = res.businesses[0].paid_funeral_count;
        this.payment_status[2].value = res.businesses[0].refund_funeral_count;

        this.funeral_status[0].value = res.businesses[0].open_funeral_count;
        this.funeral_status[1].value =
          res.businesses[0].completed_funeral_count;
        this.funeral_status[2].value =
          res.businesses[0].cancelled_funeral_count;
      });
    },
    copy() {
      this.$shilp.copy(this.businessSlug);
      this.$notify({
        type: "success",
        title: "Copied!",
        duration: 1000,
      });
    },
    prePlanningCopySlug() {
      this.$shilp.copy(this.prePlanningSlug);
      this.$notify({
        type: "success",
        title: "Copied!",
        duration: 1000,
      });
    },
    copyFuneral(uuid) {
      const link =
        window.location.origin +
        "/app/#/" +
        this.business?.url_slug +
        "/" +
        uuid;
      this.$shilp.copy(link);
      this.$notify({
        type: "success",
        title: "Copied!",
        duration: 1000,
      });
    },
  },
};
</script>

<style lang="scss">
.points-list {
  margin-top: 15px;
}
.point-box {
  &--warning {
    label {
      color: #7a4a1d;
    }
  }
  &--success {
    label {
      color: #2d6845;
    }
  }
  &--danger {
    label {
      color: #7f1e1e;
    }
  }
}
.deceased-box {
  width: 200px;
  .flex__fit {
    white-space: normal;
    flex: 0 0 160px;
  }
}
@media (max-width: 780px) {
  .director-row {
    display: block !important;
    > .column {
      & + .column {
        margin-top: 20px;
      }
    }
  }
}
@media (max-width: 425px) {
  .points-list {
    display: block !important;
    > .column {
      & + .column {
        margin-top: 10px;
      }
    }
  }
}
</style>
