export default {
  path: ":prePlanningId",
  name: "pre-planning-starting",
  redirect: { name: "pre-planning-introduction" },
  component: require("@/views/pre-planning-add/Index").default,
  props: true,
  children: [
    {
      path: "",
      component: require("@/views/pre-planning-add/Introduction").default,
      name: "pre-planning-introduction",
      props: true,
      meta: {
        label: "Introduction Details",
        breadcrumbs: [
          "dashboard",
          "pre-planning-list",
          "pre-planning-introduction",
        ],
      },
    },
    {
      path: "type-details",
      component: require("@/views/pre-planning-add/CremationDetails").default,
      name: "per-planning-cremation",
      props: true,
      meta: {
        label: "Burial or Cremation Details",
        breadcrumbs: [
          "dashboard",
          "pre-planning-list",
          "per-planning-cremation",
        ],
      },
    },
    {
      path: "my-ceremony-wishes",
      component: require("@/views/pre-planning-add/MyCeremonyWishes").default,
      name: "my-ceremony-wishes",
      props: true,
      meta: {
        label: "My Ceremony Wishes",
        breadcrumbs: ["dashboard", "pre-planning-list", "my-ceremony-wishes"],
      },
    },
    {
      path: "view-visitation-details",
      component: require("@/views/pre-planning-add/MyWakeWishes").default,
      name: "view-visitation-details",
      props: true,
      meta: {
        label: "My Viewing & Visitation (Wake) Wishes",
        breadcrumbs: [
          "dashboard",
          "pre-planning-list",
          "view-visitation-details",
        ],
      },
    },
    {
      path: "my-personal-wishes",
      component: require("@/views/pre-planning-add/MyPersonalWishes").default,
      name: "my-personal-wishes",
      props: true,
      meta: {
        label: "My Personal Wishes",
        breadcrumbs: ["dashboard", "pre-planning-list", "my-personal-wishes"],
      },
    },
    {
      path: "confirmation",
      component: require("@/views/pre-planning-add/Confirmation").default,
      name: "confirmation",
      props: true,
      meta: {
        label: "Confirmation",
        breadcrumbs: ["dashboard", "pre-planning-list", "confirmation"],
      },
    },
  ],
};
